export enum ROUTES {
    MAIN = '/',
    ABOUT = '/about',
    NEWS = '/news/:newsId?',
    RIGHT = '/right',
    DISCLOSURE = '/raskrytie_informatsii',
    KENO = '/keno_schedule',
    RAPIDO_ULTRA = '/rapidoultra_schedule',
    GAME1224 = '/1224',
    ZABAVA = '/zabava',
    CONTACTS = '/contacts',
    IDENTIFICATION = '/identification',
    ROCKETBINGO = '/rocketbingo_schedule',
    BIG_SPORTLOTO = '/5x2_schedule'
}
