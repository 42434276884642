import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';

import './index.css';
import App from './App';


ReactGA.initialize('G-7LSWQNHMLB');

ReactDOM.render(
  <BrowserRouter>
    <LastLocationProvider>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </LastLocationProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
