import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components';
import { BeInGame } from '../components/widgets/BeInGame';
import { PhotoHall } from "../components/widgets/PhotoHall";
import { Footer } from "../components/widgets/Footer";
import { HowItWas } from "../components/widgets/HowIItWas";
import { Classic } from '../components/widgets/Classic';
import { History } from '../components/widgets/History';
import { AnchorMenu } from '../components/controls/AnchorMenu';

const Main = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <PageContainer>
      <BeInGame />
      <Classic />
      <History />
      <PhotoHall />
      <HowItWas />
      {/*<Winners />*/}
      <Footer />
      <AnchorMenu list={
        [
          { name: 'Be in game', id: 'be-in-game' },
          { name: 'Classic', id: 'classic' },
          { name: 'History', id: 'history' },
          { name: 'PhotoHall', id: 'photo-hall' },
          { name: 'HowItWas', id: 'how-it-was' }/*,
          { name: 'Winners', id: 'winners' }*/
        ]
      } />
    </PageContainer>
  );
};

const PageContainer = styled.main`
  overflow: hidden;
`;

export default Main;

