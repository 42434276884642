import React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  active: boolean,
  onClick?: () => void,
  children: React.ReactNode
}

export const AnchorMenuItem = ({ active, onClick }: IProps) => {
  return (
    <Link onClick={onClick} active={active}>
      AnchorMenuItem
    </Link>
  )
};

AnchorMenuItem.defaultProps = {
  active: false
};


const Link = styled.span.attrs(() => ({
  className: 'anchor-menu-item'
}))<IProps>`
  display: block;
  font-size: 0;
  width: 9px;
  height: 9px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #000;
  ${({ active }) => active && css`
    width: 15px;
    height: 15px;
    border: 2px solid #EAC141;
    background: transparent;
  `}
`;