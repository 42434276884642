import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { media } from '../../common/styles';

type Props = {
  newsSet: News[];
}

export const NewsList = (props: Props) => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <Layout>
      <List>
        {props.newsSet.map((news) =>
          <NewsItem key={news.id}>
            <div className="date">{news.date}</div>
            <Link className="name" to={`/news/${news.id}`}>
              <span className="underlined" dangerouslySetInnerHTML={{ __html: news.title }} />
            </Link>
            <div className="announce" dangerouslySetInnerHTML={{ __html: news.announce }} />
          </NewsItem>
        )}
      </List>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 35px;

  ${media.desktop} {
    margin-left: 108px;
  }
`

const List = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  gap: 16px;

  ${media.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

const NewsItem = styled.li`
  .date {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #EAC141;
  }

  .name {
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 10px;
    text-decoration: none;
  }

  .underlined {
        border-bottom: 1px solid #FFFFFF;
  }

  .announce {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  ${media.desktop} {
    .name {
      margin-top: 20px;
    }

    .announce {
      margin-top: 10px;
    }
  }
`;
