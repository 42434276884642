import React from 'react';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive'
import { Testimony } from "./Testimony";
import { Container, Slide, Arrow } from "./styles";
import { TESTIMONIES_DATA } from "./testimoniesMock";
import { WIDTH_BREAKPOINTS } from "../../../common/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export interface IArrowProps {
    type: 'prev' | 'next';
}

const SliderArrow = ({type, ...rest}:IArrowProps) => <Arrow type={type} {...rest} />;

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
    rows: 2,
    slidesPerRow: 1,
    responsive: [
        {
            breakpoint: WIDTH_BREAKPOINTS.MOBILE,
            settings: {
                slidesToShow: 1,
                rows: 1,
            },
        }
    ]
};

export const Testimonies = () => {

    const isDesktop = useMediaQuery({minWidth: WIDTH_BREAKPOINTS.DESKTOP});
    const isMobile = useMediaQuery({maxWidth: WIDTH_BREAKPOINTS.MOBILE});

    const getUnderlayType = (i:number) => isDesktop ? (i%2 === 0 ? "left" : "right") : "left";

    return (
        <Container>
            <Slider {...settings}>
                {TESTIMONIES_DATA.map((data, i) =>
                    (<Slide key={data.name} indent={i%2 === 0 ? "right" : "left"}>
                        <Testimony
                            data={data}
                            isMobile={isMobile}
                            underlay={getUnderlayType(i)}
                        />
                    </Slide>))
                }
            </Slider>
        </Container>
    )
}
