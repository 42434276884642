import styled from 'styled-components'
import { media } from "../../../common/styles";
import triangleSvg from "../triangle.svg";

export const TitleContainer = styled.div`

  position: relative;
  top: 183px;
  z-index: 10;
  height: 220px;
  
  ${media.mobile} {
    position: relative;
    top: 40px;
    left: auto;
    margin-bottom: 63px;
  }
  
`;

export const BgTriangle = styled.div`
  position: absolute;
  left: 38px;
  top: -208px;
  width: 630px;
  height: 545px;
  background: url(${triangleSvg}) no-repeat;
  background-size: contain;
  z-index: 1;
  
  ${media.mobile} {
    top: -52px;
    width: 314px;
    height: 272px;
    left: 100px;
  }
  
`;

export const MainTitleContainer = styled.div`  
  position: relative;
  font-weight: 900;
  font-size: 87px;
  line-height: 70px;  
  text-transform: uppercase;
  font-style: italic;
  margin-bottom: 58px;
  z-index: 10;
  
  ${media.mobile} {
    font-size: 65px;
    line-height: 51px;
    margin-bottom: 30px;
  }  
`;

export const Title = styled.h2`
  color: black;
  font-size: inherit;
  font-weight: inherit;
  text-shadow: 140px -38px 0 rgba(0,0,0,0.06);
  z-index: 10;
  
  ${media.mobile} {
    text-shadow: 114px -24px 0 rgba(0,0,0,0.06);
  }
`;

export const OutlineText = styled.span`
  position: absolute;
  top: -8px;
  left: 12px;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.325);
  color: transparent;
  z-index: 10;
  
  ${media.mobile} {
    left: 8px;
  }
`;

export const SubTitle = styled.div`
  position: relative;
  color: #2D3136;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  z-index: 10;
  
  ${media.mobile} {
    font-size: 14px;
  }
`;
