import React from 'react';
import styled from 'styled-components';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { useMediaQuery } from 'react-responsive';

import { Header, Menu } from '../Header';
import { Social } from '../InfoPages/Social';
import { Footer } from '../Footer'

import { media, WIDTH_BREAKPOINTS } from '../../common/styles';

import crossSvg from './img/cross.svg';
import { SocialWrapper } from '../InfoPages/styles';

export const ContactsPage = () => {
  const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });

  return (
    <ContactsLayout>
      <ContactsPageHeader showLogo />
      <ContactsMain>
        <Container>
          <Title>Контакты</Title>
          <Content>
            <p>Почтовый адрес ООО «Спортлото»:</p>
            <p>109316, Москва, Волгоградский проспект, д. 43, корп. 3</p>
            <p>Для получения выигрышей обращайтесь в лотерейный центр «Столото»</p>
            <p>
              Москва<br />
              Волгоградский проспект, д. 43, корп. 3 (М. Текстильщики)<br />
              Режим работы консультантов по выплатам:<br />
              ежедневно, с 09:00 до 23:00<br />
              без перерыва на обед и выходных<br />
              <Link href='https://www.youtube.com/watch?time_continue=2&v=BDYY_QkQGcc'>Видеомаршрут</Link>
            </p>
          </Content>
        </Container>
        <YMaps>
          <Map
            style={{
              height: '500px',
              width: '100%'
            }}
            defaultState={{ center: [55.71400458826161, 37.718732055786695], zoom: 15 }}
          >
            <Placemark
              geometry={[55.71400458826161, 37.718732055786695]}
              propetries={{
                balloonContent: 'Адрес<br> 109316, г. Москва,<br> Волгоградский проспект, д. 43, корп. 3'
              }}
            />
          </Map>
        </YMaps>
      </ContactsMain>
      { isDesktop && <SocialWrapper><Social /></SocialWrapper>}
      <Footer />
    </ContactsLayout>
  );
};


export const ContactsLayout = styled.section`
  position: relative;
  background: #fff;
  padding: 88px 0 0;
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    background: #fff url(${crossSvg}) no-repeat 0 175px;
    padding-top: 185px;
  }
`;

export const ContactsPageHeader = styled(Header)`
  color: #000;
  
  ${Menu} {
    margin: 0 auto;
    justify-content: flex-start;
    color: #000;
    max-width: 830px;
    
    ${media.desktop} {
      flex: 1 1 auto;
      justify-content: flex-start;
    }
    
    ${media.big} {
      justify-content: center;
      .menu-link:not(:first-child) {
          margin-left: 20px;
       }   
    }    
  }
    
  .profile-button {
    color: black;
  }
`;

const ContactsMain = styled.div`
  flex-grow: 1;
  margin-bottom: 30px;
  ${media.desktop} {
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 830px;
  padding: 0 35px;
  ${media.desktop} {
    padding: 0;
  }
`;

const Title = styled.h2`
  margin-bottom: 15px;
  color: #EAC141;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  
  ${media.mobile} {
    font-size: 25px;
    line-height: 32px;
  }
`;

const Content = styled.div`
  margin-bottom: 30px;
  ${media.desktop} {
    margin-bottom: 50px;
  }
  p {
    font-size: 12px;
    line-height: 17px;
    &:not(:last-child) {
      margin-bottom: 19px;
    }
    ${media.desktop} {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #000;
  border-bottom: 1px solid #2d3136;
`;


