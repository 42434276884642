import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from "react-responsive";
import { useHistory } from 'react-router-dom'

import { media, WIDTH_BREAKPOINTS } from '../../common/styles';

import { SportlotoProduct } from '../../controls/SportlotoProduct';

import AboutBg from './img/AboutBg.svg';
import RapidoUltra from './img/rapidoUltra.svg';
import Keno from './img/keno.svg';
import MomentLotteries from './img/Logo_Horizontal_white_back.svg';
import MomentLotteriesMobile from './img/momentLotteriesMobile.svg';
import RocketBingo from './img/rocketbingo.svg';
import AllOrNothing from './img/all-or-nothing.svg';
import Zabava from './img/zabava.svg';
import BigSportloto from './img/5x2.svg';
import {Social} from "../InfoPages/Social";
import {Politics} from "../Politics";

export const AboutPage = () => {
  const isMobile = useMediaQuery({ maxWidth: WIDTH_BREAKPOINTS.MOBILE });
  const history = useHistory();
  const handleClick = (url: string) => {
    if (url.includes('http:') || url.includes('https:')) {
      window.location.href = url
    } else {
      history.push(url)
    }
  };

  return (
    <Layout>
      <Social />
      <Container>
        <Content>
          <AboutTitle>О компании</AboutTitle>
          <AboutDescription>
            <p>
              ООО «Спортлото» является оператором государственных лотерей, проводимых в поддержку развития спорта высших достижений
              и системы подготовки спортивного резерва на основании распоряжения Правительства Российской Федерации от 14.09.2009 № 1318-р.
              <br />
              Организатор лотерей: Министерство финансов Российской Федерации.
            </p>
            <p>
              Наша цель — возрождение традиций лотерей, в которых каждый участник делает вклад в развитие национального спорта
              и может выиграть крупный приз. «Спортлото» предлагает вам стать участником увлекательных и захватывающих всероссийских
              государственных тиражных лотерей «Всё или ничего» и «Забава от Русского лото», «КЕНО» и «Рокетбинго», а также бестиражных лотерей.
              <br />
              Не менее 60% всех средств, вырученных от продаж лотерейных билетов, составляют призовой фонд лотерей,
              из которого выплачиваются выигрыши победителям.
              <br />
              Целевые отчисления от проведения лотерей направляются в бюджет Российской Федерации.
            </p>
          </AboutDescription>
        </Content>
        <Products>
          <SportlotoProduct
              img={BigSportloto}
              onClickInfo={() => handleClick('/5x2_schedule')}
              onClickButton={() => handleClick('https://www.stoloto.ru/5x2/game')}
              onClickMain={() => handleClick('/5x2_schedule')}
              type='5x2'
          />
          <SportlotoProduct
              img={AllOrNothing}
              onClickInfo={() => handleClick('/1224')}
              onClickButton={() => handleClick('https://www.stoloto.ru/1224/game/')}
              onClickMain={() => handleClick('/1224')}
              type='1224'
          />
          <SportlotoProduct
              img={Zabava}
              onClickInfo={() => handleClick('/zabava')}
              onClickButton={() => handleClick('https://www.stoloto.ru/zabava/game')}
              onClickMain={() => handleClick('/zabava')}
              type='zabava'
          />
          <SportlotoProduct
            img={Keno}
            onClickInfo={() => handleClick('/keno_schedule')}
            onClickButton={() => handleClick('https://www.stoloto.ru/keno2/game')}
            onClickMain={() => handleClick('/keno_schedule')}
            type='keno'
          />
          <SportlotoProduct
              img={RocketBingo}
              onClickInfo={() => handleClick('/rocketbingo_schedule')}
              onClickButton={() => handleClick('https://www.stoloto.ru/rocketbingo/game/')}
              onClickMain={() => handleClick('/rocketbingo_schedule')}
              type='rocketbingo'
          />
          <SportlotoProduct
              img={RapidoUltra}
              onClickInfo={() => handleClick('/rapidoultra_schedule')}
              onClickButton={() => handleClick('https://www.stoloto.ru/rapido-ultra/game')}
              onClickMain={() => handleClick('/rapidoultra_schedule')}
              type='rapido-ultra'
          />
          <SportlotoProduct
              img={isMobile ? MomentLotteriesMobile : MomentLotteries}
              type='moment'
              onClickMain={() => handleClick('https://www.stoloto.ru/instant-lotteries-sportloto-new')}
          />
        </Products>
        <LegalInfo>
          Сайт sportloto.ru является зарегистрированным средством массовой информации — «Спортлото. Государственные Лотереи».
          Свидетельство о регистрации средства массовой информации: Эл № ФС77-54339 от 29.05.2013.
          Зарегистрировано в Федеральной службе по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор).
          Учредитель СМИ: ООО «Спортлото», ОГРН: 1107746125607. Адрес редакции СМИ: 109316, г. Москва, Волгоградский проспект, д. 43, корп. 3.
        </LegalInfo>
        <PoliticsWrapper>
          <Politics fontColor={'#888'} />
        </PoliticsWrapper>
      </Container>
    </Layout>
  )
};


const Layout = styled.div`
  ${media.desktop} {
    background: url(${AboutBg}) left top no-repeat;
  }
  .socials-menu {
    position: absolute;
    top: 40%;
    right: 35px;
    ${media.mobile} {
      display: none;
    }
  }
`;

const Container =  styled.div`
  max-width: 1022px;
  margin: 0 auto;
  padding: 0 35px;
  ${media.desktop} {
    padding: 0;
  }
`;

const Content = styled.article`
  margin-bottom: 40px;
  ${media.desktop} {
    padding-left: 175px;
    margin-bottom: 80px;
  }
`;

const AboutTitle = styled.h1`
  font-size: 25px;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #EAC141;
  margin-bottom: 25px;
  ${media.desktop} {
    font-size: 32px;
    line-height: 39px;
  }
`;

const AboutDescription = styled.div`
  p {
    font-size: 12px;
    line-height: 17px;
    ${media.desktop} {
      font-size: 16px;
      line-height: 24px;
    }
    & + p {
      margin-top: 20px;
    }
  }
`;

const Products = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  margin-bottom: 30px;
  @media screen and (min-width: 414px) {
    grid-template-columns: 1fr 1fr;
  }
  ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 55px;
  }
`;

const LegalInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #909090;
`;

const PoliticsWrapper = styled.div`
  margin-top: 20px;
`;
