import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import { media } from '../../common/styles';

import { AnchorMenuItem } from './AnchorMenuItem';

interface ILink {
  id: string,
  name: string
}

interface IProps {
  list:  Array<ILink>
}

export const AnchorMenu = React.memo(({ list }: IProps) => {
  const [activeElement, setActiveElement] = useState(null);

  const handleScroll = () => {
    const menuItems = list
        .map(item => {
          return document.querySelector(`#${item.id}`);
        })
        .filter(item => Boolean(item))
        .map((element: any) => ({
          height: element?.getBoundingClientRect().height,
          top: element?.offsetTop || 0,
          id: element?.id,
          bottom: element?.getBoundingClientRect().height + element?.offsetTop
        }))
        .sort((a, b) => a.top - b.top);
    const lastElement = menuItems[menuItems.length - 1].id;

    const pageCenter = (window.innerHeight / 2) + window.scrollY || 0;

    const activeElement = menuItems.find(item => {
      const coordsStart = item.top - 100;
      const coordsBottom = item.bottom + 300;
      return pageCenter >= coordsStart && pageCenter <= coordsBottom
    });

    if (activeElement) {
      setActiveElement(activeElement?.id)
    } else {
      setActiveElement(lastElement)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  });

  const goToElement = (id: string) => {
    const element = document.querySelector(`#${id}`);
    element?.scrollIntoView({
      behavior: 'smooth'
    })
  };

  return (
    <Layout>
      {list.map(link => <AnchorMenuItem key={link.id} onClick={() => goToElement(link.id)} active={link.id === activeElement}>{link.name}</AnchorMenuItem>)}
    </Layout>
  )
});

const Layout = styled.nav`
  display: none;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  z-index: 3;
  ${media.desktop} {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }
  .anchor-menu-item + .anchor-menu-item {
    margin-top: 35px;
  }
`;