export const TESTIMONIES_DATA = [
    {
        name: 'Наджия Сатдикова',
        text: '«Дед играл в «Спортлото». Стабильно, каждую неделю покупал карточку, заполнял дома, не спеша. Что-то высчитывал, анализировал… Больших выигрышей не было, но по 3 рубля выигрывал частенько. А 3 рубля были деньгами пусть небольшими, но заметными. Хлеб стоил 16 копеек, а пенсия у деда была 45 рублей. Это начало 70-х».',
    },
    {
        name: 'Ольга Козлова',
        text: '«Отец всегда покупал «Спортлото», заполняли все, я бросала квиток в ящик. Один раз выиграли 1000 руб. Хотя и не бедствовали, всё равно счастье было безгранично!»',
    },
    {
        name: 'Арсен Гегамян',
        text: '«Отец в 1978 году по пути на работу купил билет «Спортлото» и положил квитанцию в карман рубашки,забыв об этом...через несколько месяцев снова надел её, и случайно квитанция выпала из кармана. Отец пошел проверил. Выиграл Москвич 412... После этого в течение нескольких лет выигрывал фотоаппарат и тур.палатку».',
    },
    {
        name: 'Вадим Ильин',
        text: '«В конце 70-х или в начале 80-х годов моя мама, как обычно, с «получки» купила на всю семью по билету «Спортлото», по билету на каждого человека семьи и подписала их именами! Когда проверяла по таблице в газете, то мой подписанный билет оказался выигрышным на 50 рублей! На эти деньги мама купила крутую детскую коляску для моей младшей сестры!»',
    },
    {
        name: 'Андрей Жарков',
        text: '«Шел далекий 1984 год. Возвращались мы с приятелем со службы. Дембель. Настроение хорошее. Повстречался нам киоск «Спортлото». Решили приобрести один билет «5 из 36» за 30 копеек. Заполняли следующим образом: два числа зачеркнул я, два — приятель, одно — киоскер. В итоге общими усилиями угадали то ли 3, то ли 4 числа. Выигрыш был 15 рублей». ',
    },
    {
        name: 'Елена Урбанович',
        text: '«Примерно через год после смерти моей бабушки, я взялась перешивать на себя её шубку из искусственного меха (потому и разрешили). Было мне 19 лет. Бабушка очень часто покупала в булочной билетики по 30 копеек, но никогда больше рубля не выиграла. Во внутреннем кармане шубы нашла лотерейный билетик, розыгрыш которого состоялся год назад. Но не поленилась, сходила в сберкассу, проверила по газете Труд, билет был выигрышный! Но получить его было уже нельзя, полгода тогда давали на это. И с тех пор, уже 35 лет, иногда покупаю билетик. В память о бабушке».',
    },
];