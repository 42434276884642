export enum WIDTH_BREAKPOINTS {
    MOBILE = 1023,
    DESKTOP = 1024,
    BIG = 1300,
    SMALL = 329,
}

export const media = {
    // mobile: '@media only screen and (max-width: 479px)',
    // desktop: '@media only screen and (min-width: 480px)',
    // mobile: '@media only screen and (max-width: 767px)',
    // desktop: '@media only screen and (min-width: 768px)',
    small: `@media only screen and (max-width: ${WIDTH_BREAKPOINTS.SMALL}px)`,
    big: `@media only screen and (max-width: ${WIDTH_BREAKPOINTS.BIG}px)`,
    mobile: `@media only screen and (max-width: ${WIDTH_BREAKPOINTS.MOBILE}px)`,
    desktop: `@media only screen and (min-width: ${WIDTH_BREAKPOINTS.DESKTOP}px)`,
    // tablet: '@media only screen and (min-width: 480px)',
};
