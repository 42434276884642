import React from 'react';
import styled, {css} from 'styled-components';

import {Button} from '../Button';

import {media} from '../../common/styles'

import Alert from './img/alert.svg';

type ProductTypes = 'keno' | 'moment' | '1224' | 'rocketbingo' | '5x2' | 'zabava' | 'rapido-ultra';

interface IProps {
    onClickMain: () => void
    onClickInfo?: () => void
    onClickButton?: () => void
    img: string
    type: ProductTypes
}

export const SportlotoProduct = (props: IProps) => {
    const {type, img, onClickMain, onClickInfo, onClickButton} = props;

    const handleButtonClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClickButton?.()
    };

    const handleInformationClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClickInfo?.();
    };

    const handleClick = () => {
        onClickMain()
    };

    const isMoment = type === 'moment';

    let attrs = {
        onClick: handleInformationClick,
        href: isMoment ? '/usloviya_minfina_prikaz_ot_07.12.20.pdf' : undefined,
        target: isMoment ? '_blank' : undefined
    };

    return (
        <Layout type={type} onClick={handleClick}>
            <Information {...attrs}>
                <img src={Alert} alt="Информация"/>
                <span>Информация</span>
            </Information>
            <Picture className='sportloto-product__image' src={img}/>
            {onClickButton && <Button onClick={handleButtonClick}>Купить билет</Button>}
        </Layout>
    )
};


const Layout = styled.div<{ type: ProductTypes }>`
  padding: 14px 7px 24px;
  background: #F0F3F6;
  display: flex;
  flex-direction: column;
  min-height: 208px;
  cursor: pointer;
  
  ${media.desktop} {
    min-height: 283px;
    padding: 14px 16px 18px;
  }
  
  .button {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    ${media.mobile} {
      padding: 9px;
    }
  }
  
  ${({type}) => type === 'keno' && css`
    .sportloto-product__image {
      width: 109px;
      height: 56px;
      ${media.desktop} {
        width: 160px;
        height: auto;
        padding: 12px 0 10px 6px;
      }
    }`
}
  
  ${({type}) => type === '1224' && css`
    .sportloto-product__image {
      height: 100px;
      width: 135px;
      ${media.desktop} {
       padding: 7px 0 7px;
        height: 120px;
        width: 135px;
      }
    }`
}
  
  ${({type}) => type === 'zabava' && css`
    .sportloto-product__image {
      height: 100px;
      width: 135px;
      ${media.desktop} {
       padding: 7px 0 7px;
        height: 120px;
        width: 135px;
      }
    }`
}  
  
  ${({type}) => type === 'moment' && css`
    .sportloto-product__image {
      width: 124px;
      height: 110px;
      ${media.desktop} {
        width: 182px;
        height: auto; 
        padding-bottom: 44px;
      }
    }`
}
  
  ${({type}) => type === 'rocketbingo' && css`
    .sportloto-product__image {
      width: 88px;
      ${media.desktop} {
        width: 111px;
      }
    }`
}
    ${({type}) => type === '5x2' && css`
      .sportloto-product__image {
        width: 88px;
        ${media.desktop} {
          width: auto;
        }
      }`
}
    
        ${({type}) => type === 'rapido-ultra' && css`
      .sportloto-product__image {
      position: relative;
      width: 153px;
      height: 115px;
      
        ${media.desktop} {
      width: 170px;
      height: 128px;
        }
      }`
}
  }
`;

const Information = styled.a`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  text-decoration: none;
  font-size: 10px;
  line-height: 9px;
  ${media.desktop} {
    font-size: 12px;
    line-height: 16px;
   }
   
  img {
    margin-right: 4px;
    width: 12px;
    height: 12px;
    ${media.desktop} {
      margin-right: 6px;
      width: 18px;
      height: 18px;
    }
  }
  
  span {
    color: #171A1E;
    border-bottom: 1px solid #000;
    padding-bottom: 1px;
  }
`;

const Picture = styled.img`
  margin: auto;
`;
