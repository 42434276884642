import slide from './img/slide1.png'

export const NEWS = [
    {
        photo: slide,
        label: '50 лет',
        title: '«Спортлото» приглашает на фотовыставку на Никитском бульваре',
        subTitle: 'с 20 по 30 ноября 2020 года',
        description: '<p>Полвека назад, 20 октября 1970 года, в Центральном доме журналиста в Москве состоялся первый розыгрыш числовой лотереи «Спортлото». Новая лотерея быстро обрела популярность и с годами стала надежной поддержкой советского спорта и  культурным феноменом эпохи. До 70% населения СССР участвовали в лотереях «Спортлото» в надежде выиграть автомобиль или стать обладателем колоссальной суммы.</p><br />' +
            '<p>В честь юбилея легендарной лотереи с 20 по 30 ноября на Никитском бульваре в Москве пройдёт выставка, рассказывающая о полувековом пути «Спортлото». О финансовом вкладе спортивной лотереи в проведение Олимпиады-80 и о том, как традиции «Спортлото» в наше время продолжает распространитель государственных лотерей «Столото».</p>'
    }
];