import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import styled from 'styled-components'
import { useHistory } from 'react-router';
import { useLastLocation } from 'react-router-last-location';
import { api } from '../../../api';
import { media } from '../../common/styles';
import { Button } from './styles';

type Props = {
  newsId: string;
}

export const NewsDetails = (props: Props) => {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const [newsDetails, setNewsDetails] = useState<News>({} as News);

  useEffect(() => {
    api.fetchNewsDetails(props.newsId)
      .then(newsDetails => setNewsDetails(newsDetails))
  }, [props.newsId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <Layout>
      <Details key={newsDetails.id}>
        <div className="date">{newsDetails.date}</div>
        <div className="title" dangerouslySetInnerHTML={{ __html: newsDetails.name }} />
        <div className="text" dangerouslySetInnerHTML={{ __html: newsDetails.text }} />
      </Details>

      <Actions>
        <Button prev onClick={() => lastLocation ? history.goBack() : history.push('/news')} />
      </Actions>
    </Layout>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 35px;

  ${media.desktop} {
    margin-left: 108px;
  }
`

const Details = styled.article`
  min-height: 420px;

  .date {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #EAC141;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 16px;
    cursor: pointer;
  }

  .text {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    margin-top: 10px;
  }

  img {
    max-width: 100%;
    height: auto !important;
  }

  ${media.desktop} {
    .title {
      margin-top: 20px;
    }

    .text {
      margin-top: 42px;
    }
  }
`;

const Actions = styled.div`
  position: fixed;
  bottom: 36px;
  left: 33px;

  ${media.desktop} {
    position: static;
    margin-top: 60px;
  }
`;