import styled, { css } from 'styled-components'
import { TUnderlayType } from "./index";
import { media } from "../../../../common/styles";

const UNDERLAY_SHIFT = 8;
const UNDERLAY_SHIFT_MOBILE = 11;

const underlayBaseCss = css`
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #F0F3F6;
    border: 1px solid #2D3136;
`

export const getUnderlayCss = (type:TUnderlayType, shift:number) => css`

  margin-${type}: ${shift*2}px;

  &:before {
    ${underlayBaseCss};
    ${type}: -${shift*2}px;
    top: -${shift*2}px;
    z-index: 1;
  }
    
  }
  &:after {
    ${underlayBaseCss};
    ${type}: -${shift}px;        
    top: -${shift}px;
    z-index: 5;
  }  
`

interface ITestimonyWrapperProps {
    underlayDirection: TUnderlayType;
    isMobile?: boolean;
}

export const TestimonyWrapper = styled.div<ITestimonyWrapperProps>`
  width: 505px;
  height: auto;
  position: relative;
  box-sizing: border-box;
  
  ${props => getUnderlayCss(
      props.underlayDirection, 
    props.isMobile ? UNDERLAY_SHIFT_MOBILE : UNDERLAY_SHIFT
    )};
  
  ${media.mobile} {
    width: 325px;
    height: auto;
    margin: 0;
  }
  
  ${media.small} {
    width: 100%;
  }  
`;

export const TestimonyContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 42px 0;
  background: white;
  box-sizing: border-box;  
  border: 2px solid black;
  z-index: 10;
  
  ${media.mobile} {
    padding: 26px 0 35px;
  }
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 12px;
  padding: 0 20px 0 32px;
`;

const quotationCss = css`
  position: absolute;
  color: #EAC141;
  font-size: 42px;
`;

export const Text = styled.div`
  position: relative;
  padding: 0 30px 0 74px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  
  &:before {
    ${quotationCss};
    content: '«';
    left: 30px;
    top: 0;
    
    ${media.mobile} {
      left: 16px;
    }
  }
  
  &:after {
    ${quotationCss};
    content: '»';
    right: 15px;
    bottom: -5px
    
    ${media.mobile} {
      left: -12px;
    }
    
  }
  
  ${media.mobile} {
    padding: 0 29px 0 53px;
  }
`;