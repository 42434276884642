import React from 'react';
import {SocialLink} from "../../controls/SocialLink";
import { SocialsMenu } from "./styles";
import { SOCIAL } from "../../../constants/social";
import { Soctype } from "../../controls/SocialLink";

interface ISocialProps {
    items: string[];
}

export const Social = ({items}: ISocialProps) => (
    <SocialsMenu>
        {items.map(name =>
            <SocialLink
                href={SOCIAL[name as Soctype]}
                socType={name as Soctype}
                key={name}
            />)}
    </SocialsMenu>
);

Social.defaultProps = {
    items: Object.keys(SOCIAL),
}