import React from 'react';
import { YMInitializer  } from 'react-yandex-metrika';

export const Analytics = () => {
    return (
            <YMInitializer
                accounts={[21667060]}
                options={{
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                    webvisor: true,
                    trackHash: true
                }}
            />
    );
}
