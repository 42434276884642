import React from 'react';
import styled from 'styled-components';

import { media } from '../../../common/styles';
import { ButtonLink } from "../../../controls/Button";

import Ball from "../img/ball.svg";
import Stage3 from "../img/stage-3-mobile.svg";
import DividerFadeMobile from "../img/devider_fade_mobile.svg";
import Stage from "../img/stage-1.svg";

export const HistoryMobile = () => {
  return (
    <Layout>
      <StageOne>
        <StageDivider className='stage-one-divider'>
          <img src={Ball} alt="Ball"/>
          <span className="line" />
        </StageDivider>
        <StageOneContent>
          <StageOneDate>
            <StageOneTitle>
              1968-<br />
              <StageOneTitleBottom>1969</StageOneTitleBottom>
            </StageOneTitle>
          </StageOneDate>
          <StageOneText>После нескольких попыток Спорткомитет СССР наконец получает право на проведение спортивно-числовой лотереи «Спортлото» по формуле «6 из 49».
            Доходы должны направляться на развитие спорта. </StageOneText>
          <StageOneImage>
            <img src={Stage} alt=""/>
          </StageOneImage>
        </StageOneContent>
      </StageOne>
      <StageTwo>
        <StageDivider>
          <img src={Ball} alt="Ball"/>
          <span className="line" />
        </StageDivider>
        <StageTwoContent>
          <StageTwoDate>
            <StageTwoTitle>
              <StageTwoTitleTop>20 октября</StageTwoTitleTop>
              <StageTwoTitleBottom>1970</StageTwoTitleBottom>
            </StageTwoTitle>
          </StageTwoDate>
          <StageTwoText>В Центральном Доме журналиста состоялся первый розыгрыш «Спортлото». На тираж было продано 1,5 миллиона билетов.
            Участие в розыгрыше могли принять только москвичи, а один билет стоил 30 копеек.
            Главной победительницей стала инженер-экономист Лидия Морозова. Она выиграла 5000 рублей.</StageTwoText>
        </StageTwoContent>
      </StageTwo>
      <StageThree>
        <StageDivider>
          <img src={Ball} alt="Ball"/>
          <img src={DividerFadeMobile} alt="Ball"/>
        </StageDivider>
        <StageThreeDate>
          <StageThreeTitle>
            1971
          </StageThreeTitle>
          <StageThreeContent>
            <p>Билеты «Спортлото» появились в Баку, Ереване, Одессе, Львове, Киеве, Таллине, Запорожье, Свердловске, Ростове-на-Дону и Ленинграде.
              За первые 12 месяцев было продано 70 миллионов билетов.</p>
          </StageThreeContent>
        </StageThreeDate>
      </StageThree>
      <ActionWrapper>
        <ButtonLink href='https://museum.stoloto.ru/home' color='secondary' fontSize='adaptive'>Узнать больше об истории Спортлото</ButtonLink>
      </ActionWrapper>
    </Layout>
  )
};


export const Layout = styled.div`
  max-width: 375px;
  margin: 0 auto;
  ${media.desktop} {
    display: none;
  }
`;

const StageOne = styled.div`
  display: flex;
  min-height: 330px;
  padding-left: 15px;
  @media screen and (min-width: 414px) {
    padding-left: 45px;
  }
`;

const StageOneDate = styled.div``;

const StageOneTitle = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: calc(65px * 0.75);
  line-height: calc(50px * 0.75);
  color: #EAC141;
  margin-bottom: 21px;
  @media screen and (min-width: 414px) {
    font-size: 65px;
    line-height: 50px;
  }
`;

const StageOneTitleBottom = styled.span`
  padding-left: calc(75px * 0.75);
  @media screen and (min-width: 414px) {
    padding-left: calc(75px * 0.75);
  }
`;

const StageOneContent = styled.div`
  padding-left: 17px;
  img {
    width: 260px;
    left: -50px;  
    position: relative;
    @media screen and (min-width: 414px) {
      left: -75px;
      width: 317px;
    }
  }
`;

const StageOneText = styled.p`
  font-size: 12px;
  line-height: 18px;
  max-width: 235px;
  margin-bottom: 28px;
  @media screen and (min-width: 414px) {
    max-width: 255px;
  }
`;

const StageTwo = styled.div`
  display: flex;
  min-height: 440px;
  margin-top: 9px;
  padding-left: 15px;
  @media screen and (min-width: 414px) {
    padding-left: 45px;
  }
`;

const StageTwoDate = styled.div``;

const StageTwoTitle = styled.div`
  margin-bottom: 19px;
`;

const StageTwoTitleTop = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: -0.12px;
  text-transform: uppercase;
  opacity: 0.5;
  color: transparent;
  -webkit-text-stroke: 1px #747474;
`;

const StageTwoTitleBottom = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 65px;
  line-height: 44px;
  text-transform: uppercase;
  color: #EAC141;
  padding-left: 66px;
  margin-top: -4px;
  position: relative;
  z-index: 1;
`;

const StageTwoContent = styled.div`
  padding-left: 17px;
`;

const StageTwoText = styled.p`
  font-size: 12px;
  line-height: 18px;
`;

const StageDivider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img:first-child {
    margin-bottom: 6px;
  }
  .line {
    width: 2px;
    flex-grow: 1;
    background: #2D3136;
  }
`;

const StageThree = styled.div`
  display: flex;
  min-height: 300px;
  margin-top: 11px;
  padding-left: 15px;
  @media screen and (min-width: 414px) {
    padding-left: 45px;
    min-height: 335px;
  }
`;

const StageThreeDate = styled.div`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -190px;
    left: -62px;
    width: 280.65px;
    height: 242px;
    background: url(${Stage3}) no-repeat;
  }
`;

const StageThreeTitle = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 65px;
  line-height: 56px;
  text-transform: uppercase;
  color: #EAC141;
  z-index: 1;
  position: relative;
  padding-left: 13px;
`;

const StageThreeContent = styled.div`
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 12px;
    line-height: 18px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  .button {
    @media screen and (max-width: 413px) {
      font-size: 13px;
    }
    width: 100%;
  }
`;

const StageOneImage = styled.div`
  position: relative;
  img{
    position: absolute;
    left: calc(100% - 92px);
    width: 92px;
    height: 60px;
  }
`;