import React from 'react'
import styled, { css } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

interface ICustomLinkProps {
  children: React.ReactNode
  href: string
  smooth?: boolean
  className?: string
  external?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const linkCss = css`
  color: inherit;
  text-decoration: none;
`;

const StyledAnchor = styled.a`
  ${linkCss};
`;

const StyledRouterLink = styled(RouterLink)`
  ${linkCss};
`;

const StyledHashLink = styled(HashLink)`
  ${linkCss}
`;

export const Link = ({ href, children, className, smooth, external, onClick }: ICustomLinkProps) => {
  if (href.includes('http:') || href.includes('https:') || external) {
    return <StyledAnchor className={className} href={href} onClick={onClick}>{children}</StyledAnchor>
  } else {
    if (href.includes('#')) {
      return <StyledHashLink className={className} onClick={onClick} smooth={smooth} to={href}>{children}</StyledHashLink>
    }
    return <StyledRouterLink className={className} onClick={onClick} to={href}>{children}</StyledRouterLink>
  }
};

Link.defaultProps = {
  smooth: true,
};
