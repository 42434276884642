import styled, { css }from 'styled-components'
import { media } from "../../common/styles";

export const SectionContainer = styled.section`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1440px;
  height: 825px;
  background: white;
  margin: 0 auto;
  
  ${media.mobile} {
     height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }  
`;

export const getEquilateralTriangleCss = (side:number, color:string) => css`
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 ${side/2}px ${side*Math.sqrt(3)/2}px ${side/2}px;
  border-color: transparent transparent ${color} transparent;
`;

export const Triangles = styled.div`
  position: absolute;
  bottom: -35px;
  left: 8%;
  
  &:before {
   content: '';
   ${getEquilateralTriangleCss(110, '#171A1E')};
  }
  
  &:after {
   content: '';
   ${getEquilateralTriangleCss(110, '#EAC141')};
   margin-top: -22px;
   left: 0;
  }
  
  ${media.mobile} {
    bottom: -19px;
    &:before {
      ${getEquilateralTriangleCss(58, '#171A1E')};
    }
    &:after {
      ${getEquilateralTriangleCss(58, '#EAC141')};
      margin-top: -12px;
   }
  }  
`;