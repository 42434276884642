import { format as formatFNS } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export const formatDate = (date:any, pattern:string) => {
	let d = date.split(/[-T:+]/);
	let ts = new Date(d[0], d[1]-1, d[2], d[3], d[4], d[5]);

	return formatFNS(new Date(ts.toISOString()), pattern, {locale: ruLocale});
};

export const formatDateUnix = (date:any, pattern:string) => {
	const time = new Date(date * 1000);
	const toISOString = time.toISOString();

	return formatFNS(new Date(toISOString), pattern, {locale: ruLocale});
};