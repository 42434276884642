import styled from 'styled-components';

import { media } from '../../common/styles';

export const AboutLayout = styled.div`
  background: #fff;
  padding: 100px 0 60px;
  ${media.desktop} {
    padding: 178px 0 60px;
  }
`