import styled, { css } from "styled-components";
import { Link as CustomLink } from "../../controls/Link";
import { media } from "../../common/styles";
import { Icon } from "../../controls/Icon";
import crossSvg from '../../common/cross.svg';

const YELLOW_COLOR = '#EAC141';
export const CONTENT_MAX_WIDTH = 830;

export const PageContainer = styled.section`
  position: relative;
  width: 100%;
  height: 100%;
  
  ${media.desktop} {
    background: white url(${crossSvg}) no-repeat 0 175px;
  }
  
  ${media.mobile} {
    background: white;
  }  
`;

export const Content = styled.div`
  padding: 176px 10% 50px;
  margin: 0 auto;
  max-width: ${CONTENT_MAX_WIDTH}px;
  box-sizing: content-box;
  
  ${media.mobile} {
    padding-top: 88px;
  }
`;

export const textCss = css`
   font-size: 16px;
   line-height: 24px;
   font-weight: 400;
   text-align: left;
   
   ${media.mobile} {
    font-size: 12px; 
    line-height: 17px;
  }
`;

export const Text = styled.span`
  ${textCss};
`

export const List = styled.ul`
  ${textCss};
  list-style: none;
  margin-bottom: 103px;
  
  ${media.mobile} {
    margin-bottom: 40px; 
  }
`;

interface IListItemProps {
    bulleted?: boolean,
}

export const ListItem = styled.li<IListItemProps>`
  ${textCss};
  position: relative;
  padding-left: ${props => props.bulleted ? '30px' : 0};
  margin-bottom: 5px;
  
  ${props => props.bulleted && css`
      &:before {
        content: '•';
        position: absolute;
        left: 0;
        top: -2px;
        font-size: 40px;
        line-height: inherit;
        color: ${YELLOW_COLOR};    
      }
   `};

`;

export const Title = styled.h2`
  margin-bottom: 15px;
  color: ${YELLOW_COLOR};
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  
  ${media.mobile} {
    font-size: 25px;
    line-height: 32px;
  }
`;

export const Subtitle = styled.p`
  ${textCss};
  font-weight: bold;
  margin-bottom: 24px;
  
  ${media.mobile} {
    font-size: 14px;
    margin-bottom: 30px;
  }
`;


export const Link = styled(CustomLink)`
  ${textCss};
  border-bottom: 1px solid #2d3136;
`;

export const IconLink = styled(CustomLink)`
  ${Icon} {
    vertical-align: middle;
    margin-right: 5px;
  };
  
  ${Text} {
    border-bottom: 1px solid #2d3136;
  }
`;

export const PdfBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: auto;
  }

  ${Link} {
    margin-left: 10px;

    ${media.mobile} {
      margin-left: 18px;
    }
  }
`;

export const SocialList = styled.div`
  position: fixed;
  right: 35px;
  top: 335px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SocialsMenu = styled.div.attrs(() => ({
  className: 'socials-menu'
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .social-link + .social-link {
    margin-top: 0;
    
    ${media.desktop} {
      margin-top: 25px;
    }
  }
`;

export const SocialWrapper = styled.div`
  position: fixed;
  right: 35px;
  top: 335px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;