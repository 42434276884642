import React from 'react';
import {useMediaQuery} from "react-responsive";
import {
    Content,
    PageContainer,
    Title,
    SocialWrapper,
} from "../styles";
import { WIDTH_BREAKPOINTS } from "../../../common/styles";
import { DISCLOSURE_DATA } from "./data";
import { DocList } from "./DocList";
import { Footer } from "../../Footer";
import { Social } from "../Social";
import { Header } from "../../Header";
import { CONTENT_MAX_WIDTH } from "../styles";

export const DisclosurePage = () => {

    const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });

    return (
        <PageContainer>
            <Header showLogo menuMaxWidth={CONTENT_MAX_WIDTH} />
            <Content>
                <Title>Раскрытие информации</Title>
                <DocList data={DISCLOSURE_DATA} />
                { isDesktop && <SocialWrapper><Social /></SocialWrapper>}
            </Content>
            <Footer />
        </PageContainer>
    );
}
