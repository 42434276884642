import React from 'react';
import {
    TopMobileSection,
    FooterContent,
    Text,
    AppButton,
    AppIcon,
    FooterBlock,
    PhoneBlock,
    Phone
} from "./styles";
import {Social} from "../InfoPages/Social";
import {Politics} from "../Politics";
import {Logo18} from "./18";
import styled from "styled-components";

export const MobileFooter = () => (
        <FooterContent>
            <TopMobileSection>
                <FooterBlock>
                    <AppButton href="https://www.stoloto.ru/mobile-applications">
                        <AppIcon icon="apple" />
                        <AppIcon icon="android" />
                        Приложения
                    </AppButton>
                    <Politics />
                    <LegalInfo>
                        Сайт sportloto.ru является зарегистрированным средством массовой информации — «Спортлото. Государственные Лотереи».
                        Свидетельство о регистрации средства массовой информации: Эл № ФС77-54339 от 29.05.2013.
                        Зарегистрировано в Федеральной службе по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор).
                        Учредитель СМИ: ООО «Спортлото», ОГРН: 1107746125607. Адрес редакции СМИ: 109316, г. Москва, Волгоградский проспект, д. 43, корп. 3.
                        Главный редактор: Топилин С. Н.
                    </LegalInfo>
                </FooterBlock>
                <Social />
            </TopMobileSection>
            <PhoneBlock>
                <Text>Горячая линия</Text>
                <Phone>8 900 555-00-55</Phone>
                <LegalInfo2>
                    <div>Телефон общества: +7 (495) 204-87-78</div>
                    <div>Электронная почта: info@sportloto.ru</div>
                </LegalInfo2>
                <Restriction>
                    <Logo18 />
                    <div style={{paddingLeft: '10px'}}>Для лиц<br />старше 18 лет</div>
                </Restriction>
            </PhoneBlock>
        </FooterContent>
);

const LegalInfo = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #909090;
  padding-top: 30px;
`;

const LegalInfo2 = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: #909090;
  padding-top: 20px;
`;

const Restriction = styled.div`
    color: #909090;
    display: flex;
    padding-top: 20px;
`;
