import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Header } from '../components/widgets/Header';
import { News } from '../components/widgets/News';

export default function NewsPage() {
  return (
    <Layout>
      <Header showLogo white />
      <News />
    </Layout>
  )
}

const Layout = styled.div`
  min-height: 100vh;
  padding-bottom: 60px;
  background: #171A1E;
`;
