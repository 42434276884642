import keno from "../../../images/logo/keno.svg";
import rapidoUltra from "../../../images/logo/rapidoultra.svg";
import AllOrNothing from '../../../images/logo/all-or-nothing.svg';
import Zabava from '../../../images/logo/zabava.svg';
import rocketbingo from '../../../images/logo/rocketbingo.svg';
import rocketbingoSquare1 from './img/rocketbingo/square_1.svg';
import rocketbingoSquare2 from './img/rocketbingo/square_2.svg';
import rocketbingoSquare3 from './img/rocketbingo/square_3.svg';
import rocketbingoSquare4 from './img/rocketbingo/square_4.svg';
import rocketbingoSquare5 from './img/rocketbingo/square_5.svg';
import rocketbingoSquare6 from './img/rocketbingo/square_6.svg';
import rocketbingoSquare7 from './img/rocketbingo/square_7.svg';
import rocketbingoTable1 from './img/rocketbingo/table_1.jpg';
import rocketbingoTable2 from './img/rocketbingo/table_2.jpg';
import bigSportloto from '../../../images/logo/5x2.svg';
import bigSportlotoMobile from '../../../images/logo/5x2_mobile.svg';


export const mockGameInfo = [
	{
		id: 1,
		game: "keno2",
		srcLogo: keno,
		buyUrl: `https://www.stoloto.ru/keno2/game`,
		title: `«КЕНО»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Суперприз',
			rules: `Чтобы стать участником, отметьте в билете от 1 до 10 чисел в диапазоне от 1 до 80.`,
			ticket: 'Билеты'
		},
		contentInfo: {
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов 
						при проведении следующей лотереи:</p>
					<p>Распоряжение Правительства РФ от 14.09.2009 № 1318-р.</p>
					<p>Оператор: ООО «Спортлото», тел. +7 495 204-87-78.</p>
					<p>«ВГТЛ-1», алгоритм определения выигрышей № 1.</p>
					<p>Организатор: Минфин России.</p>
					<p>Призовой фонд — 70% выручки от проведения лотерей.</p>
					<p>Место проведения розыгрыша тиража — г. Москва.</p>
					<p>Результаты тиража публикуются на сайтах <a href='https://www.stoloto.ru'>stoloto.ru</a> и <a href='https://www.lotonews.ru'>lotonews.ru</a> 
						в течение 10 дней после его проведения.</p>
					<p>Выплата выигрышей осуществляется распространителями или оператором лотереи не позднее 30 дней с даты проведения соответствующего тиража 
						и продолжается не менее 6, но не более 7 месяцев с момента опубликования в средствах массовой информации результатов данного тиража.</p>`,
			rules: `<p>Билет считается выигрышным в первом туре, если его номер совпал с девятизначной выигрышной комбинацией первого тура в точном порядке слева направо.</p>
					<p>Если номер билета содержит менее 9 разрядов, то недостающие разряды считаются стоящими слева и равными нулю. 
						Розыгрыш во втором туре основан на совпадении чисел, размещенных на билете, с числами выигрышной комбинации второго тура. 
						Выигрышная комбинация второго тура определяется путём формирования случайной последовательности из 20 неповторяющихся чисел из 80 (1-80). 
						Размер выигрыша и порядок его получения определяется в соответствии с условиями лотереи.</p>`
		},
	},{
		id: 3,
		game: "1224",
		srcLogo: AllOrNothing,
		buyUrl: `https://www.stoloto.ru/1224/game/`,
		title: `«Всё или ничего»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Суперприз',
			maxWin: '',
			rules: 'В этой лотерее вы можете выиграть суперприз, не угадав ни одного числа. Также суперприз получают те, кто угадает 12 чисел из 24.',
			ticket: 'Билеты'
		},
		contentInfo: {
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов 
					при проведении следующей лотереи:</p>
					<p>Распоряжение Правительства РФ от 14.09.2009 № 1318-р.</p>
					<p>Оператор: ООО «Спортлото», тел. +7 495 204-87-78.</p>
					<p>«ВГТЛ-2», алгоритм определения выигрышей № 9.</p>
					<p>Организатор: Минфин России.</p>
					<p>Призовой фонд — 60% выручки от проведения лотерей.</p>
					<p>Место проведения розыгрыша тиража — г. Москва.</p>
					<p>Результаты тиража публикуются на сайтах stoloto.ru и lotonews.ru в течение 10 дней после его проведения.</p>
					<p>Выплата выигрышей осуществляется распространителями или оператором лотереи не позднее 30 дней с даты проведения соответствующего тиража и продолжается не менее 6, но не более 7 месяцев с момента опубликования в средствах массовой информации результатов данного тиража.</p>`,
			rules: `<p>Розыгрыш основан на совпадении цифр, размещенных на билете, с номерами, выданными лотерейным оборудованием. Выигрышная комбинация формируется путём выпадения 12 чисел (1-24).</p>`,
			eComNaming: `<p>«Всё или ничего» и «Забава от Русского лото» являются равнозначными коммерческими наименованиями лотереи «ВГТЛ-2», алгоритм определения выигрышей № 9.</p>`
		},
	},{
		id: 4,
		game: "zabava",
		srcLogo: Zabava,
		buyUrl: `https://www.stoloto.ru/zabava/game/`,
		title: `«Забава от Русского лото»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Суперприз',
			maxWin: '',
			rules: 'В этой лотерее вы можете выиграть суперприз, не угадав ни одного числа. Также суперприз получают те, кто угадает 12 чисел из 24.',
			ticket: 'Билеты'
		},
		contentInfo: {
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов 
					при проведении следующей лотереи:</p>
					<p>Распоряжение Правительства РФ от 14.09.2009 г. № 1318-р.</p>
					<p>Оператор: ООО «Спортлото», тел. +7 495 204-87-78.</p>
					<p>«ВГТЛ-2», алгоритм определения выигрышей № 9.</p>
					<p>Организатор: Минфин России</p>
					<p>Призовой фонд — 60% выручки от проведения лотерей.</p>
					<p>Место проведения розыгрыша тиража — г. Москва.</p>
					<p>Результаты тиража публикуются на сайтах stoloto.ru и lotonews.ru в течение 10 дней после его проведения.</p>
					<p>Выплата выигрышей осуществляется распространителями или оператором лотереи не позднее 30 дней с даты проведения соответствующего тиража и продолжается не менее шести, но не более семи месяцев с момента опубликования в средствах массовой информации результатов данного тиража.</p>`,
			rules: `<p>Розыгрыш основан на совпадении цифр, размещенных на билете, с номерами, выданными лотерейным оборудованием. Выигрышная комбинация формируется путём выпадения 12 чисел (1-24).</p>`,
			eComNaming: `<p>«Всё или ничего» и «Забава от Русского лото» являются равнозначными коммерческими наименованиями лотереи «ВГТЛ-2», алгоритм определения выигрышей № 9.</p>`
		},
	},{
		id: 5,
		game: "rocketbingo",
		srcLogo: rocketbingo,
		buyUrl: `https://www.stoloto.ru/rocketbingo/game/`,
		title: `«Рокетбинго»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Джекпот',
			maxWin: '',
			rules: `<p>Чтобы стать участником, приобретите один или несколько билетов, содержащих игровое поле бинго лотереи.</p>
					<p>В «Рокетбинго» несколько выигрышных категорий с фиксированными призами и суперприз. Каждый билет может выиграть только в одной категории. Выигрыш зависит от того, на каком шаге розыгрыша на билете будет закрыта выигрышная фигура.</p>`,
			ticket: 'Билеты'
		},
		contentInfo: {
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов 
			при проведении следующей лотереи:</p>
			<p>Распоряжение Правительства Российской Федерации от 14.09.2009 № 1318-р.</p>
			<p>Оператор: ООО «Спортлото», тел. +7 495 204-87-78.</p>
			<p>«ВГТЛ-1», алгоритм определения выигрышей № 6.</p>
			<p>Организатор: Минфин России.</p>
			<p>Призовой фонд — 70% выручки от проведения лотерей.</p>
			<p>Место проведения розыгрыша тиража — г. Москва.</p>
			<p>Билет имеет одно игровое поле, розыгрыш проводится в несколько туров.</p>
			<br>
			<p>Розыгрыш</p>
			<p>Основные правила игры подобны правилам «Бинго-75»: в каждом билете поле 5 х 5 уже заполнено числами, центральная клетка остается пустой. В игре участвуют 75 неповторяющихся чисел (от 1 до 75), которые выпадают поочередно по одному числу за ход.</p>
			<p>Розыгрыш проводится в несколько туров и идет до 35-го хода, если оператором не определено иное количество ходов.</p>
			<br>
			<p>«Первый тур»</p>
			<p>Выигрывают билеты, в которых 4 числа, расположенных в игровом поле одним из способов, указанных на рисунках «Диагональные линии»*, «Средние линии»**, или 5 чисел в любой горизонтальной или вертикальной линии, расположенных в игровом поле способом, указанным на рисунке «Любая линия»***, совпадут с числами, выпавшими в процессе розыгрыша до 6-го хода включительно. Выигравшие билеты не участвуют в продолжении розыгрыша, кроме категории «Джекпот».</p>
			<br>
			<p>«Второй тур»</p>
			<p>Выигрывают билеты, в которых 4 числа, расположенных в игровом поле одним из способов, указанных на рисунках «Диагональные линии»*, «Средние линии»**, или 5 чисел в любой горизонтальной или вертикальной линии, расположенных в игровом поле способом, указанным на рисунке «Любая линия»***, совпадут с числами, выпавшими в процессе розыгрыша до 15-го хода включительно. Выигравшие билеты не участвуют в продолжении розыгрыша, кроме категории «Джекпот».</p>
			<br>
			<p>«Третий тур»</p>
			<p>Выигрывают билеты, в которых 4 числа, расположенных в игровом поле одним из способов, указанных на рисунках «Диагональные линии»*, «Средние линии»**, или 5 чисел в любой горизонтальной или вертикальной линии, расположенных в игровом поле способом, указанным на рисунке «Любая линия»***, совпадут с числами, выпавшими в процессе розыгрыша до 28-го хода включительно. Выигравшие билеты не участвуют в продолжении розыгрыша, кроме категории «Джекпот».</p>
			<br>
			<p>«Четвертый тур»</p>
			<p>Выигрывают билеты, в которых 4 числа, расположенных в игровом поле одним из способов, указанных на рисунках «Диагональные линии»*, «Средние линии»**, или 5 чисел в любой горизонтальной или вертикальной линии, расположенных в игровом поле способом, указанным на рисунке «Любая линия»***, совпадут с числами, выпавшими в процессе розыгрыша до 35-го хода включительно. Выигравшие билеты не участвуют в продолжении розыгрыша, кроме категории «Джекпот».</p>
			<br>
			<p>*Диагональные линии</p>
			<br>
			<p>
				<img src='${rocketbingoSquare1}' />
				<img src='${rocketbingoSquare2}' />
			</p>
			<br>
			<p>**Средние линии</p>
			<br>
			<p>
				<img src='${rocketbingoSquare3}' />
				<img src='${rocketbingoSquare4}' />
			</p>
			<br>
			<p>***Любая линия</p>
			<p>горизонтальные линии из 5 чисел</p>
			<br>
			<p>
				<img src='${rocketbingoSquare5}' />
			</p>
			<br>
			<p>вертикальные линии из 5 чисел</p>
			<br>
			<p>
				<img src='${rocketbingoSquare6}' />
			</p>
			<br>
			<p>Джекпот</p>
			<p>Выигрывают билеты, в которых на 35-м ходу или раньше 16 чисел, расположенных в игровом поле способом, указанным на рисунке «Рамка», раньше других совпадут с выпавшими в процессе розыгрыша числами.</p>
			<br>
			<p>Рамка</p>
			<br>
			<p>
				<img src='${rocketbingoSquare7}' />
			</p>
			<br>
			<p>Что можно выиграть</p>
			<p>Джекпот является накапливаемым. Поэтому если в текущем тираже Джекпот не будет разыгран, накопленная сумма переходит на следующий тираж. Минимальный гарантированный Джекпот лотереи составляет 1 500 000 рублей.</p>
			<br><br>
			<p>
				<img src='${rocketbingoTable1}' style="width: 100%" />
			</p>
			<br>
			<p>Призовой фонд — 70% с каждого проданного билета.</p>
			<p>* Фиксированный выигрыш на один выигрышный билет.</p>
			<p>График тиражей: ежедневно.</p>
			<p>
				<img src='${rocketbingoTable2}' style="width: 100%" />
			</p>`,
			rules: `<p>
						Размер выигрыша и порядок его получения определяется в соответствии с условиями лотереи. 
						Результаты тиража публикуются на сайтах <a href='https://www.stoloto.ru'>stoloto.ru</a> и <a href='https://www.lotonews.ru'>lotonews.ru</a> 
						в течение 10 дней после его проведения. Выплата выигрышей производится в точках распространения билетов и оператором лотереи 
						в течение 180 календарных дней с даты предъявления информации о билете для получения выигрыша. 
						Предоставляя данные о номере своего телефона и (или) адресе электронной почты, вы даёте согласие на обработку своих персональных данных 
						и получение писем и СМС от оператора лотереи и (или) уполномоченных им лиц (сообщений, содержащих секретный код, рекламных сообщений и т. д.).</p>`
		},
	},{
		id: 6,
		game: "5x2",
		srcLogo: bigSportloto,
		srcLogoMobile: bigSportlotoMobile,
		buyUrl: `https://www.stoloto.ru/5x2/game/`,
		title: `«Большое Спортлото»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Суперприз',
			maxWin: '',
			rules: 'Единственная лотерея «Спортлото» с 3 категориями выигрышей от 1 000 000 рублей в каждой.',
			ticket: 'Билеты'
		},
		contentInfo: {
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов 
					при проведении следующей лотереи:</p>
					<br>
					<p>Оператор лотереи: ООО «Спортлото», тел. +7 495 204-87-78.</p>
					<br>
					<p>Распоряжение Правительства Российской Федерации от 14.09.2009 № 1318-р.</p>
					<br>
					<p>«ВГТЛ-2», алгоритм определения выигрышей № 14.</p>
					<br>
					<p>Организатор лотереи: Министерство финансов Российской Федерации.</p>
					<br>
					<p>Призовой фонд — 60% от выручки.</p>
					<br>
					<p>Место проведения розыгрыша — г. Москва.</p>
					<br>
					<p>Результаты тиража публикуются на сайтах <a href='https://www.stoloto.ru'>stoloto.ru</a> и <a href='https://www.lotonews.ru'>lotonews.ru</a> в течение 10 дней после его проведения.</p>
					<br>
					<p>Выплата, передача или предоставление выигрышей начинается в точках распространения билетов и оператором лотереи не позднее 30 дней после проведения соответствующего тиража лотереи и продолжается не менее 6 месяцев, но не более 7 месяцев с момента опубликования в СМИ результатов розыгрыша призового фонда такого тиража.</p>
					<br>
					<p>Выплата, передача или предоставление выигрыша осуществляется не позднее 180 (ста восьмидесяти) календарных дней с даты предъявления участником лотереи информации об электронном лотерейном билете.</p><br>`,
			rules: `<br><p>Розыгрыш основан на совпадении цифр, размещенных на билете, с номерами, полученными при проведении розыгрыша. Выигрышная комбинация формируется путём случайного выпадения 5 чисел (1-50) и двух чисел (1-10). Размер выигрыша и порядок его получения определяется в соответствии с условиями лотереи.</p><br>`
		},
	},
	{
		//FIXME "подтягивается из систем"
		id: 32,
		game: "rapido-ultra",
		srcLogo: rapidoUltra,
		buyUrl: `https://www.stoloto.ru/rapido-ultra/game`,
		title: `«Рапидо Ультра»`,
		contentDraw: {
			title: 'Время проведения розыгрышей',
			nextDraw: 'Следующий тираж',
			winnings: 'Выигрыши',
			superPrize: 'Суперприз',
			maxWin: '',
			rules: 'В этой лотерее 9 категорий выигрышей. Все выигрыши, кроме суперприза, фиксированные. Чтобы стать участником лотереи. нужно выбрать минимум 8 чисел от 1 до 20 в пером игровом поле билета и 1 число от 1 до 4 - во втором.',
			ticket: 'Билеты'
		},
		contentInfo: {
			//FIXME "подтягивается из систем"
			title: `<p>Настоящим ООО «Спортлото» информирует об использовании электронных лотерейных билетов при проведении следующей лотереи:</p>
					<p>Оператор лотереи: ООО «Спортлото», тел. +7 495 204-87-78.</p>
					<p>Распоряжение Правительства Российской Федерации от 14.09.2009 № 1318-р.</p>
					<p>«ВГТЛ-1», алгоритм определения выигрышей № 7.</p>
					<p>Организатор лотереи: Министерство финансов Российской Федерации.</p>
					<p>Призовой фонд — 70% от выручки.</p>
					<p>Место проведения розыгрыша — г. Москва.</p>
					<p>Результаты тиража публикуются на сайтах 
					<a href='https://www.stoloto.ru'>stoloto.ru</a> и <a href='https://www.lotonews.ru'>lotonews.ru</a> в 
					течение 10 дней после его проведения.</p>
					<p>Выплата, передача или предоставление выигрышей начинается в точках распространения билетов и оператором лотереи не позднее 30 дней после проведения соответствующего тиража лотереи и продолжается не менее 6 месяцев, но не более 7 месяцев с момента опубликования в СМИ результатов розыгрыша призового фонда такого тиража.</p>
                    <p>Выплата, передача или предоставление выигрыша осуществляется не позднее 180 (ста восьмидесяти) календарных дней с даты предъявления участником лотереи информации об электронном лотерейном билете.</p>`,
			rules: `<p>Розыгрыш основан на совпадении цифр, размещенных на билете, с номерами, полученными при проведении розыгрыша. Выигрышная комбинация формируется путём случайного выпадения восьми чисел (1-20) и одного числа (1-4). Размер выигрыша и порядок его получения определяется в соответствии с условиями лотереи. </p>`
		},
	}
];
