import styled, { css } from 'styled-components'
import arrowLeft from './arrow-left.svg';

export const Button = styled.button<{ prev?: boolean, next?: boolean, disabled?: boolean }>`
    width: 52px;
    height: 16px;
    padding: 0;
    opacity: 1;
    background: url(${arrowLeft}) center center no-repeat;
    color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    ${({ prev }) => prev && css`
      margin-right: 8px;
    `}

    ${({ next }) => next && css`
      transform: rotate(180deg);
    `}

    ${({ disabled }) => disabled && css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

    &:hover {
      opacity: 0.5;
    }
`;
