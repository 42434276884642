import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { GameInfo } from "../components/widgets/GameInfo";
import { Header } from "../components/widgets/Header";
import { Social } from "../components/widgets/InfoPages/Social";
import { useMediaQuery } from "react-responsive";
import { WIDTH_BREAKPOINTS } from "../components/common/styles";
import { SocialWrapper } from '../components/widgets/InfoPages/styles';

export const BigSportloto = () => {
  const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <main>
      <Header showLogo className='header-about' />
      <GameInfo lotteryName='5x2' isDesktop={isDesktop} />
        { isDesktop && <SocialWrapper><Social /></SocialWrapper>}
    </main>
  )
};
