import React from 'react';
import styled from 'styled-components';
import { media } from '../../common/styles';

export type Soctype = 'VK' | 'OK' | 'RT'

interface IProps {
  href: string,
  socType: Soctype
}

const socTypes = {
  VK: require('./Vk.svg'),
  OK: require('./Ok.svg'),
  RT: require('./Rutube.svg'),
};

export const SocialLink = ({ href, socType }: IProps) => {
  return (
    <Link className='social-link' href={href}>
      <img src={socTypes[socType]} alt={socType} />
    </Link>
  )
};


const Link = styled.a`
  display: inline-flex;
  ${media.mobile} {
    margin-bottom: 15px;
  }
`;
