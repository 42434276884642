import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { WIDTH_BREAKPOINTS } from "../../common/styles";
import { DesktopFooter } from "./DesktopFooter";
import { MobileFooter } from "./MobileFooter";
import {
    FooterContainer
} from "./styles";

export const Footer = () => {

  const isMobile = useMediaQuery({ maxWidth: WIDTH_BREAKPOINTS.MOBILE });

  return (
    <FooterContainer>
        {isMobile ? <MobileFooter /> : <DesktopFooter />}
    </FooterContainer>
  )
}