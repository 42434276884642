import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { AboutLayout } from '../components/widgets/AboutLayout'
import { Header } from '../components/widgets/Header'
import { AboutMain } from './about/AboutMain';

export const About = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <AboutLayout>
      <Header className='header-about' showLogo />
      <AboutMain />
    </AboutLayout>
  )
};
