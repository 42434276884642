import { createContext } from 'react';

type TAuthContext = {
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>> | (() => void),
    isAuth: boolean
}

export const AuthContext = createContext<TAuthContext>({
    isAuth: window.localStorage.getItem('isLoggedIn') === 'true',
    setIsAuth: () => undefined,
});