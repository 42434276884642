import axios from 'axios';

export const requestLocal = axios.create({
    baseURL: '/',
    timeout: 0
})

export const request = axios.create({
    baseURL: (window as any).stolotoApiBaseUrl,
    timeout: 0,
    headers: {
        'gosloto-partner': (window as any).stolotoPartnerToken,
    },
});

export const oauthRequest = axios.create({
    baseURL: '/',
    timeout: 0,
    headers: {
        'authorization': 'Basic U3BvcnRsb3RvOmV6QXdKRmVn',
    },
});

// Хост для запросов /oauth
declare var stolotoOauth: string;


export const api = {
    fetchWinnersList: () => {
        return requestLocal.get('/api/winners')
          .then(({data}: any) => data ?? [])
          .catch();
    },

    fetchWinnerPhotos: (photoName: string) => {
        return requestLocal.get(`/webdav/${photoName}`)
          .then(({ data }: any) => data ?? []);
    },

    fetchNewsList: (): Promise<News[]> => {
        return request.get('/service/news/sportloto')
            .then(({ data }: any) => data?.news ?? [])
            .catch(errorHandler);
    },

    fetchNewsDetails: (newsId: any): Promise<News> => {
        return request.get(`/service/news/sportloto/${newsId}`)
            .then(({ data }) => data?.news ?? {})
            .catch(errorHandler);
    },

    fetchDrawDetails: (lotteryName: any): Promise<any> => {
        return request.get(`/service/draws/future?game=${lotteryName}`)
            .then(({ data }) => data?.draws ?? [])
            .catch(errorHandler);
    },

    fetchGamesDetails: (): Promise<any> => {
        return request.get(`/service/games/info-new`)
            .then(({ data }) => data?.games ?? [])
            .catch(errorHandler);
    },

    fetchUserStatusDetails: (accessToken: string): Promise<any> => {
        request.defaults.headers.common['gosloto-token'] = accessToken;

        return request.get(`/users/identification/status`, {})
            .then(({ data }) => data ?? {})
            .catch(errorHandler);
    },
};

export const apiOauth = {
    fetchOauthToken: (grant_type: string, redirect_uri: string, code: any): Promise<any> => {
        return oauthRequest.post(`${stolotoOauth}/oauth/token`, null, {params: {grant_type, redirect_uri, code}})
            .then(({ data }) => data?.access_token ?? {})
            .catch(errorHandler);
    }
};

const errorHandler = ({ response }: any): Promise<any> => {
    const requestStatus = response?.data?.requestStatus;
    if (requestStatus !== 'success') {
        const errors = response?.data?.errors;
        console.error(errors);
        return Promise.reject(errors);
    }
    return Promise.resolve();
};

// Пример как замокать ответ сервера: выполняем реальный запрос, который можно видеть в Chrome Dev Tools.
// Если он вернул 404 -- это ловит первый .catch(), который через Promise загружает данные из мока.
// fetchNewsList: (): Promise<News[]> => {
//     return api.fetchBaseUrl()
//         .then(request => request.get('/service/news/sportloto'))
//         .catch((): Promise<any> => new Promise((resolve, reject) => {
//             setTimeout(() => resolve(require('./mocks/p.api.mobile.api.v30.service.news.sportloto.ts').success), 0);
//         }))
//         .then(({ data }) => data?.news ?? [])
//         .catch(errorHandler);
// },
