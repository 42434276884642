import React from 'react';
import styled from "styled-components";
import {
    List,
    ListItem,
    IconLink,
    Subtitle,
    Text
} from "../styles";
import { Icon } from "../../../controls/Icon";

interface IItemProps {
    name: string;
    url: string;
}

const Item = ({name, url}: IItemProps) => (
    <ListItem>
        <IconLink external href={url}>
            <Icon name="pdf" width={20} height={26} />
            <Text>{name}</Text>
        </IconLink>
    </ListItem>
);

interface IYearProps {
    year: string | number;
    items: IItemProps[];
}

const YearGroup = styled(List)`
  margin-bottom: 30px;
`;

const YearTitle = styled(Subtitle)`
  margin-bottom: 10px;
`;
const Year = ({year, items}: IYearProps) => (
    <div>
        <YearTitle>{year}</YearTitle>
        <YearGroup>
            {items.map(item => <Item url={item.url} name={item.name} key={item.name} />)}
        </YearGroup>
    </div>
)

interface IDocListProps {
    data: {
        [key: string]: IItemProps[],
    }
}

const DocListContainer = styled(List)`
  margin: 50px 0 100px;
`;

export const DocList = ({data}: IDocListProps) => (
    <DocListContainer>
        {Object.keys(data).reverse().map(year => <Year year={year} items={data[year]} key={year} />)}
    </DocListContainer>
);