import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { useParams } from 'react-router-dom';
import { api } from '../../../api';
import { media } from '../../common/styles';
import { NewsList } from './NewsList';
import { NewsDetails } from './NewsDetails';
import { Politics } from "../Politics";

// для режима разработки, чтобы отображались новости
/*const mockNews = [
    {
        "id": 2654244358,
        "name": "Главные события в&nbsp;&laquo;Рокетбинго&raquo; с&nbsp;19&nbsp;по&nbsp;25&nbsp;июня",
        "title": "Новости «Рокетбинго» с 19 по 25 июня",
        "text": null,
        "announce": "<p>Участники выиграли более 15 миллионов рублей!</p>",
        "date": "2023-06-26"
    },
    {
        "id": 2654242941,
        "name": "Главные события в&nbsp;&laquo;КЕНО&raquo; с&nbsp;19&nbsp;по&nbsp;25&nbsp;июня",
        "title": "Новости «КЕНО» с 19 по 25 июня",
        "text": null,
        "announce": "<p>Три участника выиграли крупные призы!</p>",
        "date": "2023-06-26"
    },
    {
        "id": 2654239148,
        "name": "Главные события в&nbsp;лотереях &laquo;Всё или ничего&raquo; и&nbsp;&laquo;Забаве от&nbsp;Русского лото&raquo; с&nbsp;19&nbsp;по&nbsp;25&nbsp;июня",
        "title": "Главные события в лотереях «Всё или ничего» и «Забаве от Русского лото» с 19 по 25 июня",
        "text": null,
        "announce": "<p>Разыграно два суперприза!</p>",
        "date": "2023-06-26"
    },
    {
        "id": 2651569441,
        "name": "Главные события в&nbsp;&laquo;Рокетбинго&raquo; с&nbsp;12&nbsp;по&nbsp;18&nbsp;июня",
        "title": "Главные события в «Рокетбинго» с 12 по 18 июня",
        "text": null,
        "announce": "<p>Узнайте подробности!</p>",
        "date": "2023-06-23"
    }
];*/

export const News = () => {
  const { newsId } = useParams<{ newsId?: string }>();
  const [newsList, setNewsList] = useState<News[]>([]);

  useEffect(() => {
    api.fetchNewsList()
      .then(newsList => setNewsList(newsList))
      // .catch(() => { // для режима разработки, чтобы отображались новости
      //     setNewsList(mockNews);
      // })
  }, []);

  return (
    <Layout>
      <Caption>
        <div className="text">Новости</div>
      </Caption>

      {newsId === undefined && newsList.length &&
        <ArticlesWrapper>
            <NewsList newsSet={newsList} />
        </ArticlesWrapper>
      }

      {newsId !== undefined &&
        <ArticlesWrapper>
            <NewsDetails newsId={newsId} />
        </ArticlesWrapper>
      }

      <PoliticsWrapper>
        <Politics />
      </PoliticsWrapper>
    </Layout>
  )
}

const Layout = styled.main`
  min-height: 100vh;
  padding: 125px 35px 12px 35px;
  overflow: hidden;

  ${media.desktop} {
    width: 840px;
    padding: 212px 0 140px 0;
    margin: 0 auto ;
  }
`

const Caption = styled.div`
  position: relative;
  flex: none;
  width: 32px;

  .text {
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 32px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
    color: #EAC141;
  }

  ${media.desktop} {
    width: 64px;

    .text {
      font-size: 90px;
      line-height: 64px;
    }
  }
`;

const ArticlesWrapper = styled.div`
  display: flex;
  padding-left: 32px;
  padding-bottom: 50px;
   
  ${media.desktop} {
    padding-left: 64px;
  }
`;

const PoliticsWrapper = styled.div`
  padding-left: 67px;
            
  ${media.desktop} {
    padding-left: 172px;
  }
`;
