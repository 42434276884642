import React from 'react';
import styled from 'styled-components';

import { media } from '../../common/styles';

import classicBg from './img/classic_bg.jpg';

export const Classic = () => {
  return (
    <Layout id='classic'>
      <Container>
        <Title>
          <span>Актуальная</span>
          <span>классика: </span>
          <span className='accent'>«Спортлото» 2020</span>
        </Title>
        <Inner>
          <ImageBlock>
            <img src={classicBg} alt="Классика" />
          </ImageBlock>
          <Content>
            <p>Вы знали, что легендарное «Спортлото», бесконечно любимое жителями СССР и увековеченное Леонидом Гайдаем в одноименном фильме, появилось ровно 50 лет назад, в 1970 году?
              Возможно, несколько позже вам даже довелось отмечать счастливые числа в билете, прикрывая его рукой, чтобы никто не подсмотрел.
              Тогда вы помните это ощущение: пока остаешься в игре, возможно всё.
            </p>
            <p>Хотели бы вы снова это почувствовать? А может быть, открыть для себя дух и традиции того самого
              «Спортлото»?</p>
            <h3>Вы можете!</h3>
            <p>В 2020 году «Спортлото» возвращается и становится еще ближе, еще доступнее и еще любимее. <span>Испытывайте
              удачу. Выигрывайте.&nbsp;</span>
              Помогайте развитию спорта каждым купленным билетом и закаляйте свою собственную волю к победам.
            </p>
            <p>Главное отличие «Спортлото» 2020 года от советского: играть в него можно так, как удобно вам.
              Покупайте билеты на stoloto.ru, в мобильном приложении «Столото», лотерейных киосках, точках продаж наших партнеров и выигрывайте!</p>
          </Content>
        </Inner>
      </Container>
    </Layout>
  )
};


const Layout = styled.section`
  padding: 60px 0;
  ${media.desktop} {
    padding: 80px 0 154px;
  }
`;

const Container = styled.div`
  margin: auto;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.desktop} {
    align-items: flex-start;
    padding: 0 35px 0 14.5%;
  }
`;

const Title = styled.h2`
  font-size: calc(35px * 0.9);
  line-height: calc(29px * 0.9);
  font-weight: 900;
  font-style: italic;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  @media screen and (min-width: 414px) {
    font-size: 35px;
    line-height: 29px;
  }
  ${media.desktop} {
    margin: 0;
    font-size: calc(87px * 0.75);
    line-height: calc(70px * 0.75);
    padding-left: 14.5%;
    white-space: nowrap;
    span + span {
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 1380px) {
    font-size: 87px;
    line-height: 70px;
  }

  .accent {
    color: #EAC141;
  }
`;

const Inner = styled.div`
  display: flex;
  margin-top: -40px;
  flex-direction: column;
  ${media.desktop} {
    margin-top: -20px;
    flex-direction: row;
  }
`;

const ImageBlock = styled.div`
  position: relative;
  margin-left: auto;
  @media screen and (min-width: 414px) {
    margin-left: 75px;
  }
  ${media.desktop} {
    padding: 0 26px 26px 0;
    margin-left: 0;
  }
  img {
    width: 256.18px;
    height: 314.07px;
    ${media.desktop} {
      width: 478px;
      height: 586px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 255px;
    height: 304px;
    top: 24px;
    left: 16px;
    border: 2px solid #EAC141;
    z-index: -1;
   ${media.desktop} {
     top: 45px;
     left: 33px;
     width: 471px;
     height: 567px;
   }
  }
`;

const Content = styled.div`
  padding-top: 50px;
  p {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 17px;
    &:last-of-type {
      margin-bottom: 0;
    }
    ${media.desktop} {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
    }
    span {
      font-weight: 700;
    }
  }
  h3 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    ${media.desktop} {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  ${media.desktop} {
    padding-top: 80px;
    padding-left: 17px;
    max-width: 520px;
  }
`;