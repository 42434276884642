import React, { useState } from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle, css } from 'styled-components'
import { ReactComponent as IconClose } from './img/close.svg';
import { media, WIDTH_BREAKPOINTS } from '../common/styles'
import { useMediaQuery } from "react-responsive";

interface IProps {
    isOpen: boolean
    link?: any
    children: React.ReactNode,
    getIsOpen: any
}

export const ModalContainer = ({children, isOpen, link: Component, getIsOpen}:IProps) => {
    const [modalIsOpen, setIsOpen] = useState(isOpen);
    const openModal = () => setIsOpen(true);
    const closeModal = () => {
        setIsOpen(false);
        getIsOpen(false);
    };
    const isMobile = useMediaQuery({ maxWidth: WIDTH_BREAKPOINTS.MOBILE });
    const isIOS = navigator.userAgent.includes('iPhone OS');

    const customStyles = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '0',
            border: '0',
            padding: '3px',
            background: 'transparent',
            height: isMobile ? '100%' : 'auto'
        },
        overlay: {
            backgroundColor: 'rgba(23, 26, 30, 0.7)',
            zIndex: 1000
        }
    };

    return (
        <>
            <GlobalStyle isOpen={modalIsOpen} isIOS={isIOS} />
            {Component && <Component onClick={openModal} />}
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Modal"
            >
                <CloseButton onClick={closeModal}>
                    <IconClose />
                </CloseButton>
                <Wrapper>
                    {children}
                </Wrapper>
            </Modal>
        </>
    )
};

ModalContainer.defaultProps = {
    isOpen: false,
    link: null,
    getIsOpen: () => {}
};

const CloseButton = styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 54px;
    top: 54px;
    border: 0;
    outline: none;
    cursor: pointer;
    padding: 0;
    background: transparent;
    fill: #C8C8C8;
    
    ${media.small}{
        right: 58px;
        top: 58px;
    }
    
    ${media.desktop}{
        fill: #fff;
        right: 0;
        top: 0;
    }
`;
const Wrapper = styled.div`
    padding: 60px 25px 40px;
    background: #fff;
    min-width: 346px;
    margin: 15px;
    
    ${media.small}{
        min-width: 320px;
    }
    
    ${media.desktop}{
        fill: #fff;
        padding: 65px;
        margin: 20px;
    } 
`;

const GlobalStyle = createGlobalStyle<{isOpen?: boolean, isIOS?: boolean}>`
  body {
    ${({ isOpen, isIOS }) => isOpen && isIOS && css`position: fixed;`}
  }
`;