import React from 'react';
import styled from 'styled-components';
import {
	Title,
	PageContainer,
	CONTENT_MAX_WIDTH,
	Content,
	textCss,
	Link
} from '../styles';
import { Header } from '../../Header';
import { POINT_LIST } from './mock';
import { Footer } from '../../Footer';

export const IdentificationPage = () => {
	return (
		<PageContainer>
			<Header showLogo menuMaxWidth={CONTENT_MAX_WIDTH}/>
			<Content>
				<Title>Зачем нужна идентификация и как ее пройти</Title>
				<Paragraph>Для получения лотерейного выигрыша от 15 000 рублей участнику необходимо пройти процедуру идентификации,
					согласно Федеральным законам от 11.11.2003 № 138-ФЗ «О лотереях» и от 07.08.2001 № 115-ФЗ «О противодействии
					легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма».
				</Paragraph>
				<Paragraph>
					Для проведения идентификации оператору лотереи ООО «Спортлото» нужно знать ваши данные,
					подтвержденные действительным документом, удостоверяющим личность.
				</Paragraph>
				<Paragraph>
					Пройти идентификацию у оператора лотереи вы можете в перечисленных ниже точках распространения лотерей или&nbsp;
					<Link href='https://www.stoloto.ru/wallet_identification'>при содействии ООО РНКО «Единая касса»</Link>.
				</Paragraph>
				<SubTitle>
					Вариант 1
				</SubTitle>
				<Paragraph>
					<Strong>
						3 шага прохождения идентификации:
					</Strong>
				</Paragraph>
				<Paragraph>
					1. Зарегистрируйтесь <Link href='https://www.stoloto.ru/registration'>на сайте stoloto.ru</Link><br />
					2. Заполните <Link href='https://www.stoloto.ru/private/ident'>анкету</Link><br />
					3. Выберите точку распространения лотерей из списка, расположенного ниже, где вам удобно пройти
					идентификацию. Возьмите с собой документ, удостоверяющий личность. Скажите сотруднику точки, что вы хотите
					пройти идентификацию, предъявите ему документ и сообщите код, который вы получили по СМС.<br />
				</Paragraph>
				<SubTitle>
					Вариант 2
				</SubTitle>
				<Paragraph>
					Перейдите <Link href='https://www.stoloto.ru/wallet_identification'>по ссылке</Link> и пройдите идентификацию
					при содействии ООО РНКО «Единая касса», организации, которой в
					силу закона, операторы лотерей поручили проведение идентификации участников лотерей.
				</Paragraph>
				<Paragraph>
					Соблюдайте инструкции ООО РНКО «Единая касса». Если по каким-либо причинам идентификация выполнена не была,
					воспользуйтесь вариантом 1.
				</Paragraph>
				<Paragraph>
					<Strong>
						Внимание! Если ваши данные, которые были указаны в анкете, изменятся, то идентификацию нужно пройти
						повторно.
					</Strong>
				</Paragraph>
				<Paragraph>
					<Link href='https://www.stoloto.ru/map'>https://www.stoloto.ru/map</Link>  - адреса распространителей лотерей,
					в которых вы можете пройти идентификацию и подтвердить личные данные.
				</Paragraph>
			</Content>
			<Footer />
		</PageContainer>
	);
};

const Paragraph = styled.p`
	${textCss};
	margin-bottom: 20px;
`;

const SubTitle = styled.div`
	font-size: 18px;
	font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-top: 20px;
  margin-bottom: 24px;
`;

const Strong = styled.span`
	font-weight: bold;
`;

const PointText = styled.p`
	${textCss};
`;