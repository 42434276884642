import React from 'react';
import styled from 'styled-components';

interface IPoliticsProps {
    fontColor?: string;
}

export const Politics = ({fontColor}: IPoliticsProps) => {
    return <div>
            <a href={"/Politika_v_otnoshenii_obrabotki_personalnih_dannih_2023.pdf"}
               target={"_blank"}
               style={{color: fontColor ?? '#fff'}}
            >
                Политика в отношении обработки персональных данных ООО «Спортлото»
            </a>
        </div>;
};
