import styled from "styled-components";
import pdf from './icons/pdf.svg';
import apple from "./icons/apple.svg";
import android from "./icons/android.svg";

const ICONS_SVG:{[key: string]: string} = {
  apple: apple,
  android: android,
  pdf: pdf,
};

interface IIconProps {
  width?: number,
  height?: number,
  name: string,
}

export const Icon = styled.div<IIconProps>`
    display: inline-block;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background: url(${props => ICONS_SVG[props.name]}) no-repeat;
    background-size: contain;
`;
