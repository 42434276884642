import React, { useState } from 'react';
// import ReactGA from 'react-ga';
import { Switch, Route } from 'react-router-dom'
import { About } from './pages/About';
import Main from './pages/Main';
import News from './pages/News';
import Rights from "./pages/Rights";
import Disclosure from "./pages/Disclosure";
import Contacts from "./pages/Contacts";
import { ROUTES } from "./constants/routes";
import { Keno } from "./pages/Keno";
import { Analytics } from "./Analytics";
import { Game1224 } from './pages/Game1224';
import { Zabava } from './pages/Zabava';
import { Identification } from './pages/Identification';
import { RocketBingo } from './pages/RocketBingo';
import { BigSportloto } from './pages/BigSportloto';
import { AuthContext } from './context/AuthContext';
import {RapidoUltra} from "./pages/RapidoUltra";

export default function App() {
    const [isAuth, setIsAuth] = useState(false);

    return (
        <AuthContext.Provider value={{
            isAuth,
            setIsAuth
        }}>
            <Analytics />
            <Switch>
                <Route path={ROUTES.ABOUT}>
                    <About />
                </Route>
                <Route path={ROUTES.NEWS}>
                    <News />
                </Route>
                <Route path={ROUTES.RIGHT}>
                    <Rights />
                </Route>
                <Route path={ROUTES.IDENTIFICATION}>
                    <Identification />
                </Route>
                <Route path={ROUTES.DISCLOSURE}>
                    <Disclosure />
                </Route>
                <Route path={ROUTES.CONTACTS}>
                    <Contacts />
                </Route>
                <Route path={ROUTES.BIG_SPORTLOTO}>
                    <BigSportloto />
                </Route>
                <Route path={ROUTES.KENO}>
                    <Keno />
                </Route>
                <Route path={ROUTES.RAPIDO_ULTRA}>
                    <RapidoUltra />
                </Route>
                <Route path={ROUTES.GAME1224}>
                    <Game1224 />
                </Route>
                <Route path={ROUTES.ZABAVA}>
                    <Zabava />
                </Route>
                <Route path={ROUTES.ROCKETBINGO}>
                    <RocketBingo />
                </Route>
                <Route path={ROUTES.MAIN}>
                    <Main />
                </Route>
            </Switch>
        </AuthContext.Provider>
    );
}
