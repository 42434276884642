import React from 'react';
import styled from 'styled-components'
import { media } from '../../common/styles'
import { ReactComponent as UserIconSvg } from './img/user-icon.svg'

interface IProps {
  onClick?: () => void
  children: React.ReactNode
}

export const ProfileButton = ({ onClick, children }: IProps) => {
  return (
    <Layout onClick={onClick} className='profile-button'>
        <UserIcon>
            <UserIconSvg />
        </UserIcon>
      <ProfileButtonInner>{children}</ProfileButtonInner>
    </Layout>
  )
};

export const Layout = styled.button`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-left: 20px;
  background: none;
  border: none;
  outline: none;
  cursor:pointer;
  color: inherit;
 
  ${media.desktop} {
    flex-direction: row;
  }
`

const UserIcon = styled.div`
  color: inherit;  
  width: 20px;
  height: 20px;
  margin-top: -3px;
  
  ${media.desktop} {
      margin-right: 17px;
      width: 26px;
      height: 26px;
      display: inline-block;
  }
  
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }  
`;

const ProfileButtonInner = styled.span`
  font-size: 10px;
  line-height: 10px;
  padding-top: 5px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: inherit;
  
  ${media.desktop} {
    margin-top: -4px;
    font-size: 12px;
    line-height: 19px;
    position: absolute;
  }
`;

const User = styled.svg`
  width: 20px;
  height: 20px;
  fill: #fff;
  ${media.desktop} {
    margin-right: 17px;
    width: 26px;
    height: 26px;
  }
`;

