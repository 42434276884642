import React from "react";

export const Logo18 = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 100.495 100.346" className="QdG7mD">
        <g fill="#B4B4B5">
            <path d="M30.875 27.215h-6.918c-2.659 0-4.028.456-4.028 2.66v2.508c0 2.205 1.369 2.66 4.028 2.66h1.597c.988 0 1.443.533 1.443 2.053v32.84c0 2.661.457 4.029 2.662 4.029h2.583c2.205 0 2.662-1.368 2.662-4.029V29.875c0-2.204-1.369-2.66-4.029-2.66z"></path>
            <path d="M78.297 4.409C75.333 1.521 71.608 0 67.123 0H33.371c-4.485 0-8.286 1.521-11.174 4.409L4.332 22.197C1.521 25.01 0 28.734 0 32.84v34.283c0 4.713 1.597 8.362 4.789 11.479l16.952 16.951c3.344 3.345 7.068 4.865 11.858 4.789h33.296c4.637 0 8.438-1.369 11.858-4.789l16.952-16.951c3.116-3.117 4.789-6.843 4.789-11.479V32.84c0-4.105-1.521-7.83-4.333-10.643L78.297 4.409zm15.585 40.137c0 1.141-.533 1.748-1.597 1.748h-5.093v-3.572c0-2.66-.381-4.028-2.661-4.028H82.25c-2.279 0-2.66 1.368-2.66 4.028v3.572h-3.572c-2.66 0-4.029.381-4.029 2.661v2.433c0 2.28 1.369 2.661 4.029 2.661h3.572v3.572c0 2.66.381 4.028 2.66 4.028h2.281c2.28 0 2.661-1.368 2.661-4.028v-3.572h5.093c.988 0 1.521.532 1.597 1.52v11.555c0 2.812-.837 4.94-2.813 6.842l-16.952 16.95c-1.978 1.977-4.182 2.89-6.917 2.89H33.295c-2.736 0-4.94-.913-6.917-2.89L9.427 73.965c-1.977-1.977-2.891-4.029-2.813-6.842V33.6c0-2.812.913-5.246 2.966-7.298L26.834 8.817c1.749-1.747 3.953-2.28 6.461-2.28H67.2c2.508 0 4.712.533 6.461 2.28l17.256 17.484c1.976 2.052 2.965 4.485 2.965 7.298v10.947z"></path>
            <path d="M63.93 49.716c4.257-3.116 4.333-6.31 4.333-8.895v-2.584c-.456-8.591-3.344-11.859-13.303-11.859-9.957 0-12.846 3.269-13.303 11.859v2.584c0 2.585.076 5.778 4.333 8.895-5.093 1.976-5.473 6.31-5.473 12.01 0 8.438 2.964 12.619 14.442 12.848 11.479-.229 14.444-4.409 14.444-12.848.001-5.701-.379-10.035-5.473-12.01zm-8.97-16.345c4.182 0 5.854 1.521 5.854 6.614 0 4.94-1.673 6.614-5.854 6.614-4.18 0-5.853-1.674-5.853-6.614 0-5.093 1.673-6.614 5.853-6.614zm0 33.98c-4.864 0-6.613-1.899-6.613-6.689 0-4.789 1.749-6.993 6.613-6.993 4.865 0 6.614 2.204 6.614 6.993s-1.749 6.689-6.614 6.689z"></path>
        </g>
    </svg>;
};
