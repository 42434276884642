import React from 'react';
// import styled from 'styled-components';

import { AboutPage } from '../../components/widgets/AboutPage'

export const AboutMain  = () => {
  return (
      <AboutPage />
  )
};


// const Layout = styled.div``;