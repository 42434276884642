import React from "react";
import styled from "styled-components";

import { ButtonLink } from '../../../controls/Button';

import { media } from '../../../common/styles';

import Ball from "../img/ball.svg";
import Stage from "../img/stage-1.svg";
import Stage2 from "../img/stage-2.svg";
import DividerFade from "../img/devider_fade.svg";
import BigBall from "../img/Stage-3-big-ball.svg";
import Stage3 from "../img/stage-3.svg";


export const HistoryDesktop = () => {
  return (
    <Layout>
      <StageOne>
        <StageOneDate>
          <StageOneTitle>
            <StageOneTitleTop>1968-</StageOneTitleTop><br />
            1969
          </StageOneTitle>
        </StageOneDate>
        <StageDivider>
          <img src={Ball} alt="Ball"/>
          <span className="line" />
        </StageDivider>
        <StageOneContent>
          <p>После нескольких попыток Спорткомитет СССР наконец получает право на проведение спортивно-числовой лотереи «Спортлото» по формуле «6 из 49».
            Доходы должны направляться на развитие спорта. </p>
          <StageOneImage>
            <img src={Stage} alt=""/>
          </StageOneImage>
        </StageOneContent>
      </StageOne>
      <StageTwo>
        <StageTwoContent>
          <p>В Центральном Доме журналиста состоялся первый розыгрыш «Спортлото». На тираж было продано 1,5 миллиона билетов.
            Участие в розыгрыше могли принять только москвичи, а один билет стоил 30 копеек.
            Главной победительницей стала инженер-экономист Лидия Морозова. Она выиграла 5000 рублей.</p>
        </StageTwoContent>
        <StageDivider>
          <img src={Ball} alt="Ball"/>
          <span className="line" />
        </StageDivider>
        <StageTwoDate>
          <StageTwoTitle>
            <StageTwoTitleTop>20 октября</StageTwoTitleTop>
            <StageTwoTitleBottom>1970</StageTwoTitleBottom>
          </StageTwoTitle>
          <img src={Stage2} alt="20 октября" />
        </StageTwoDate>
      </StageTwo>
      <StageThree>
        <StageThreeDate>
          <StageThreeTitle>
            1971
          </StageThreeTitle>
        </StageThreeDate>
        <StageDivider>
          <img src={Ball} alt="Ball"/>
          <img src={DividerFade} alt="Ball"/>
        </StageDivider>
        <StageThreeContent>
          <p>Билеты «Спортлото» появились в Баку, Ереване, Одессе, Львове, Киеве, Таллине, Запорожье, Свердловске, Ростове-на-Дону и Ленинграде.
            За первые 12 месяцев было продано 70 миллионов билетов.</p>
          <img src={BigBall} alt="tickets" />
        </StageThreeContent>
      </StageThree>
      <ActionWrapper>
        <ButtonLink href='https://museum.stoloto.ru/home' color='secondary'>Узнать больше об истории Спортлото</ButtonLink>
      </ActionWrapper>
    </Layout>
  )
};

const Layout = styled.div`
  display: none;
  ${media.desktop} {
   display: block; 
  }
`;

const StageOne = styled.div`
  display: flex;
  min-height: 300px;
`;

const StageOneDate = styled.div`
  width: 428px;
  flex-shrink: 0;
`;

const StageOneTitle = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 87px;
  line-height: 70px;
  color: #EAC141;
  text-align: right;
  @media screen and (min-width: 1440px) {
      padding-right: 66px;
  }
`;

const StageOneTitleTop = styled.span`
  padding-right: 90px;
`;

const StageOneContent = styled.div`
  padding-left: 18px;
  p {
    font-size: 20px;
    line-height: 26px;
    max-width: 595px;
    margin-bottom: 25px;
  }
`;

const StageTwo = styled.div`
  display:flex;
  min-height: 440px;
  margin-top: 14px;
`;

const StageTwoDate = styled.div`
  padding-left: 77px;
  img {
    padding-left: 230px;
    @media screen and (min-width: 1440px) {
      padding-left: 260px;  
    }
  }
`;

const StageTwoTitle = styled.div`
  margin-bottom: 13px;
`;

const StageTwoTitleTop = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 39px;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  opacity: 0.5;
  color: transparent;
  -webkit-text-stroke: 1px #747474;
`;

const StageTwoTitleBottom = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 87px;
  line-height: 70px;
  text-transform: uppercase;
  color: #EAC141;
  padding-left: 105px;
  margin-top: -13px;
  position: relative;
  z-index: 1;
`;

const StageTwoContent = styled.div`
  padding-right: 26px;
  width: 428px;
  flex-shrink: 0;
  p {
    font-size: 16px;
    line-height: 24px;
    text-align: right;
  }
`;

const StageDivider = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img:first-child {
    margin-bottom: 6px;
  }
  .line {
    width: 2px;
    flex-grow: 1;
    background: #2D3136;
  }
`;

const StageThree = styled.div`
  display:flex;
  min-height: 230px;
  margin-top: 9px;
`;

const StageThreeDate = styled.div`
  width: 428px;
  flex-shrink: 0;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -260px;
    right: 20px;
    width: 382.52px;
    height: 349px;
    background: url(${Stage3}) no-repeat;
  }
  img {
    padding-left: 270px
  }
`;

const StageThreeTitle = styled.p`
  font-style: italic;
  font-weight: 900;
  font-size: 87px;
  line-height: 70px;
  text-transform: uppercase;
  color: #EAC141;
  padding-right: 90px;
  margin-top: -9px;
  text-align: right;
  z-index: 1;
  position: relative;
`;

const StageThreeContent = styled.div`
  display: flex;
  padding-left: 24px;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 16px;
    line-height: 24px;
    max-width: 595px;
  }
  img {
    margin-left: auto;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  .button {
    width: 363px;
  }
`;

const StageOneImage = styled.div`
  position: relative;
  img{
    position: absolute;
    left: 300px;
    top: 30px;
  }
`;