export const DISCLOSURE_DATA = {
  2023: [
    {
      name: 'Аудиторское заключение за 2023 год',
      url: '/pdf/Аудиторское заключение за 2023 год.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 за 2023 год',
      url: '/pdf/Отчет о проведении ВГТЛ-1 за 2023 год.pdf'
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 за 2023 год',
      url: '/pdf/Отчет о проведении ВГТЛ-2 за 2023 год.pdf'
    },
    {
      name: 'Отчет ВГБЛ-1 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-1 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-2 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-3 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-4 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-5 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-6 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-7 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-8 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-9 за 2023 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2023 год',
      url: '/pdf/Отчет ВГБЛ-10 за 2023 год.pdf',
    }
  ],
  2022: [
    {
      name: 'Аудиторское заключение за 2022 год',
      url: '/pdf/Аудиторское заключение за 2022 год.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 за 2022 год',
      url: '/pdf/Отчет о проведении ВГТЛ-1 за 2022 год.pdf'
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 за 2022 год',
      url: '/pdf/Отчет о проведении ВГТЛ-2 за 2022 год.pdf'
    },
    {
      name: 'Отчет ВГБЛ-1 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-1 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-2 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-3 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-4 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-5 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-6 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-7 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-8 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-9 за 2022 год.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2022 год',
      url: '/pdf/Отчет ВГБЛ-10 за 2022 год.pdf',
    }
  ],
  2021: [
    {
      name: 'Аудиторское заключение за 2021 год',
      url: '/pdf/auditorskoe_zaklyuchenie_2021.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 за 2021 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_2021.pdf'
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 за 2021 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_2021.pdf'
    },
    {
      name: 'Отчет ВГБЛ-1 за 2021 год',
      url: '/pdf/otchet_vgbl-1_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2021 год',
      url: '/pdf/otchet_vgbl-2_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2021 год',
      url: '/pdf/otchet_vgbl-3_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2021 год',
      url: '/pdf/otchet_vgbl-4_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2021 год',
      url: '/pdf/otchet_vgbl-5_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2021 год',
      url: '/pdf/otchet_vgbl-6_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2021 год',
      url: '/pdf/otchet_vgbl-7_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2021 год',
      url: '/pdf/otchet_vgbl-8_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2021 год',
      url: '/pdf/otchet_vgbl-9_za_2021_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2021 год',
      url: '/pdf/otchet_vgbl-10_za_2021_g.pdf',
    }
  ],
  2020: [
    {
      name: 'Аудиторское заключение за 2020 год',
      url: '/pdf/auditorskoe_zaklyuchenie_2020.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 за 2020 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_2020.pdf'
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 за 2020 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_2020.pdf'
    },
    {
      name: 'Отчет ВГБЛ-1 за 2020 год',
      url: '/pdf/otchet_vgbl-1_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2020 год',
      url: '/pdf/otchet_vgbl-2_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2020 год',
      url: '/pdf/otchet_vgbl-3_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2020 год',
      url: '/pdf/otchet_vgbl-4_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2020 год',
      url: '/pdf/otchet_vgbl-5_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2020 год',
      url: '/pdf/otchet_vgbl-6_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2020 год',
      url: '/pdf/otchet_vgbl-7_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2020 год',
      url: '/pdf/otchet_vgbl-8_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2020 год',
      url: '/pdf/otchet_vgbl-9_za_2020_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2020 год',
      url: '/pdf/otchet_vgbl-10_za_2020_g.pdf',
    }
  ],
  2019: [
    {
      name: 'Аудиторское заключение за 2019 год',
      url: '/pdf/auditorskoe_zaklyuchenie_2019.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 за 2019 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_2019.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 за 2019 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_2019.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2019 год',
      url: '/pdf/otchet_vgbl-1_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2019 год',
      url: '/pdf/otchet_vgbl-2_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2019 год',
      url: '/pdf/otchet_vgbl-3_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2019 год',
      url: '/pdf/otchet_vgbl-4_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2019 год',
      url: '/pdf/otchet_vgbl-5_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2019 год',
      url: '/pdf/otchet_vgbl-6_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2019 год',
      url: '/pdf/otchet_vgbl-7_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2019 год',
      url: '/pdf/otchet_vgbl-8_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2019 год',
      url: '/pdf/otchet_vgbl-9_za_2019_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2019 год',
      url: '/pdf/otchet_vgbl-10_za_2019_g.pdf',
    },
  ],
  2018: [
    {
      name: 'Аудиторское заключение за 2018 год',
      url: '/pdf/auditorskoe_zaklyuchenie_2018.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2018 год',
      url: '/pdf/otchet_vgbl-1_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2018 год',
      url: '/pdf/otchet_vgbl-2_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2018 год',
      url: '/pdf/otchet_vgbl-3_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2018 год',
      url: '/pdf/otchet_vgbl-4_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2018 год',
      url: '/pdf/otchet_vgbl-5_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2018 год',
      url: '/pdf/otchet_vgbl-6_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2018 год',
      url: '/pdf/otchet_vgbl-7_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2018 год',
      url: '/pdf/otchet_vgbl-8_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2018 год',
      url: '/pdf/otchet_vgbl-9_za_2018_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2018 год',
      url: '/pdf/otchet_vgbl-10_za_2018_g.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 Кено-Спортлото за 2018 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_keno-sportloto_za_2018_g.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 6 из 49 и Матчбол – Спортлото за 2018 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_6_iz_49_matchball-sportloto_za_2018_g.pdf',
    },
  ],
  2017: [
    {
      name: 'Аудиторское заключение за 2017 год',
      url: '/pdf/auditorskoe_zaklyuchenie_2017.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2017 год',
      url: '/pdf/otchet_vgbl-1_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2017 год',
      url: '/pdf/otchet_vgbl-2_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2017 год',
      url: '/pdf/otchet_vgbl-3_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2017 год',
      url: '/pdf/otchet_vgbl-4_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2017 год',
      url: '/pdf/otchet_vgbl-5_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2017 год',
      url: '/pdf/otchet_vgbl-6_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2017 год',
      url: '/pdf/otchet_vgbl-7_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2017 год',
      url: '/pdf/otchet_vgbl-8_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2017 год',
      url: '/pdf/otchet_vgbl-9_za_2017_g.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2017 год',
      url: '/pdf/otchet_vgbl-10_za_2017_g.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 Кено-Спортлото за 2017 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_keno-sportloto_za_2017_g.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 6 из 49-Спортлото за 2017 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_6_iz_49-sportloto_za_2017_g.pdf',
    },
  ],
  2016: [
    {
      name: 'Аудиторское заключение за 2016 год',
      url: '/pdf/zakluchenie_sportloto_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2016 год',
      url: '/pdf/otchet_vgbl-1_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2016 год',
      url: '/pdf/otchet_vgbl-2_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2016 год',
      url: '/pdf/otchet_vgbl-3_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2016 год',
      url: '/pdf/otchet_vgbl-4_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2016 год',
      url: '/pdf/otchet_vgbl-5_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2016 год',
      url: '/pdf/otchet_vgbl-6_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2016 год',
      url: '/pdf/otchet_vgbl-7_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2016 год',
      url: '/pdf/otchet_vgbl-8_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2016 год',
      url: '/pdf/otchet_vgbl-9_2016.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2016 год',
      url: '/pdf/otchet_vgbl-10_2016.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 КЕНО-Спортлото 2016 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_sportloto_6_iz_49_2016.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 Спортлото 6 из 49 2016 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_sportloto_6_iz_49_2016.pdf',
    },
  ],
  2015: [
    {
      name: 'Аудиторское заключение за 2015 год',
      url: '/pdf/zakluchenie_sportloto_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2015 год',
      url: '/pdf/otchet_vgbl-1_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2015 год',
      url: '/pdf/otchet_vgbl-2_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2015 год',
      url: '/pdf/otchet_vgbl-3_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2015 год',
      url: '/pdf/otchet_vgbl-4_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2015 год',
      url: '/pdf/otchet_vgbl-5_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2015 год',
      url: '/pdf/otchet_vgbl-6_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2015 год',
      url: '/pdf/otchet_vgbl-7_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2015 год',
      url: '/pdf/otchet_vgbl-8_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2015 год',
      url: '/pdf/otchet_vgbl-9_2015.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2015 год',
      url: '/pdf/otchet_vgbl-10_2015.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 КЕНО-Спортлото 2015 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_keno_sportloto_2015.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 Спортлото 6 из 49 2015 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_sportloto_6_iz_49_2015.pdf',
    },
  ],
  2014: [
    {
      name: 'Аудиторское заключение за 2014 год',
      url: '/pdf/zakluchenie_sportloto_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-1 за 2014 год',
      url: '/pdf/otchet_vgbl-1_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-2 за 2014 год',
      url: '/pdf/otchet_vgbl-2_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-3 за 2014 год',
      url: '/pdf/otchet_vgbl-3_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-4 за 2014 год',
      url: '/pdf/otchet_vgbl-4_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-5 за 2014 год',
      url: '/pdf/otchet_vgbl-5_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-6 за 2014 год',
      url: '/pdf/otchet_vgbl-6_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-7 за 2014 год',
      url: '/pdf/otchet_vgbl-7_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-8 за 2014 год',
      url: '/pdf/otchet_vgbl-8_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-9 за 2014 год',
      url: '/pdf/otchet_vgbl-9_2014.pdf',
    },
    {
      name: 'Отчет ВГБЛ-10 за 2014 год',
      url: '/pdf/otchet_vgbl-10_2014.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-1 КЕНО-Спортлото 2014 год',
      url: '/pdf/otchet_o_provedenii_vgtl-1_keno_sportloto_2014.pdf',
    },
    {
      name: 'Отчет о проведении ВГТЛ-2 Спортлото 6 из 49 2014 год',
      url: '/pdf/otchet_o_provedenii_vgtl-2_sportloto_6_iz_49_2014.pdf',
    },
  ],
  2013: [
    {
      name: 'Аудиторское заключение за 2013 год',
      url: '/pdf/Auditor_conclusion_2013.pdf',
    },
    {
      name: 'Годовой отчет за 2013 год',
      url: '/pdf/sportloto_annual_report_2013.pdf',
    },
  ],
  2012: [
    {
      name: 'Аудиторское заключение за 2012 год',
      url: '/pdf/Auditor_conclusion_2012.pdf',
    },
    {
      name: 'Годовой отчет за 2012 год',
      url: '/pdf/sportloto_annual_report_2012.pdf',
    },
  ],
  2011: [
    {
      name: 'Аудиторское заключение за 2011 год',
      url: '/pdf/Auditor_conclusion_2011.pdf',
    },
    {
      name: 'Годовой отчет за 2011 год',
      url: '/pdf/sportloto_annual_report_2011.pdf',
    },
  ],
  2010: [
    {
      name: 'Аудиторское заключение за 2010 год',
      url: '/pdf/Auditor_conclusion_2010.pdf',
    },
    {
      name: 'Годовой отчет за 2010 год',
      url: '/pdf/sportloto_annual_report_2010.pdf',
    },
  ],
}
