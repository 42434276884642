import React from 'react';
import styled from 'styled-components';

import { HistoryDesktop } from './HistoryDesktop';
import { HistoryMobile } from './HistoryMobile';

import { media } from '../../common/styles';

import BigBall from './img/Stage-3-big-ball.svg';

export const History = () => {
  return (
    <Layout id='history'>
      <Container>
        <Title>История «спортлото»</Title>
        <StagesWrapper>
          <HistoryDesktop />
          <HistoryMobile />
          <BigBallImg src={BigBall} />
        </StagesWrapper>
      </Container>
    </Layout>
  )
};

const Layout = styled.section`
  background: #F0F3F6;
  padding: 40px 0;
  ${media.desktop} {
    padding: 100px 0 90px;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const Title = styled.h2`
  font-size: 25px;
  line-height: 29px;
  letter-spacing: -0.15px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  ${media.desktop} {
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.2px;
    margin-bottom: 100px;
  }
`;

const StagesWrapper = styled.div`
  padding: 0 10px;
  max-width: 1110px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 414px) {
    padding: 0 35px;
  }
  @media screen and (min-width: 1440px) {
      margin: 0;
      padding: 0 192px 0 208px;
      max-width: none;
  }
`;

const BigBallImg = styled.img`
  position: absolute;
  bottom: 80px;
  right: -35px;
  width: 186px;
  height: 169.57px;
  display: none;
  @media screen and (min-width: 320px) {
    display: block;
    bottom: 54px;
  }  
  ${media.desktop} {
    display: none;
  }
`;