import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {media} from '../../common/styles'
import {ButtonLink} from "../../controls/Button";
import {Link} from "../../controls/Link";
import {ModalContainer} from "../../modalContainer";
import {formatDate, formatDateUnix} from "../../../utils/date";
import {mockGameInfo} from "./mocks";
import {api} from "../../../api";
import {formatNumber} from '../../../utils/formatNumber';

interface IItem {
    number: number,
    date: string
    previousDates?: any
}

interface IDateProps {
    item: IItem,
    index: number
}

const DrawerDates = ({item, index}: IDateProps) => {
    const [show, setShow] = useState(false);

    return (
        <div key={index}>
            <DrawDateParent onClick={() => setShow(!show)}>
                {`Тираж №${item.number} - ${formatDate(item.date, 'dd MMMM yyyy в HH:mm:ss')}`}
                {!show ? ' 🠗' : ' 🠕'}
            </DrawDateParent>

            <DrawDatesList show={show}>
                {item?.previousDates.map((i: string, k: number) => (
                    <div key={k}>
                        — ( предыдущее значение даты и времени тиража: {formatDate(i, 'dd MMMM yyyy в HH:mm:ss')} )
                    </div>
                ))}
            </DrawDatesList>
        </div>
    );
};

const LinkModal = (props: any) => <span {...props}>Ближайшие тиражи</span>;

const ModalInfo = ({data, lotteryName}: any) => {
    return (
        <div>
            <ModalTitle>Доступны электронные лотерейные билеты ближайших тиражей лотереи {lotteryName}:</ModalTitle>
            <ModalContent>
                {
                    data?.map((item: IItem, index: number) => (
                        item?.previousDates
                            ? <DrawerDates item={item} index={index}/>
                            : <div key={index}>
                                {`Тираж №${item.number} - ${formatDate(item.date, 'dd MMMM yyyy в HH:mm:ss')}`}
                            </div>
                    )) || 'Нет данных'
                }
            </ModalContent>
        </div>
    )
};

interface IProps {
    lotteryName: string,
    isDesktop: boolean
}

export const GameInfo = ({lotteryName, isDesktop}: IProps) => {
    const archiveDrawUrl = `https://${isDesktop ? '' : 'm.'}stoloto.ru/${lotteryName}/archive`;

    const [futureDraw, setFutureDraw] = useState<any>();
    const [gameInfo, setGameInfo] = useState<any>();
    const [gameDraw, setGameDraw] = useState<any>();
    const [nextDateGame, setNextDateGame] = useState<string>('');

    const draw = gameDraw?.draw;
    const contentDraw = gameInfo?.contentDraw;
    const date = draw?.date;
    const srcLogo = isDesktop ? gameInfo?.srcLogo : gameInfo?.srcLogoMobile ?? gameInfo?.srcLogo;

    useEffect(() => {
        api.fetchDrawDetails(lotteryName)
            .then(drawDetails => setFutureDraw(drawDetails))
    }, [lotteryName]);

    useEffect(() => {
        api.fetchGamesDetails()
            .then(gameDetails => {
                const filterGame = gameDetails.filter((item: any) => item.name === lotteryName);

                if (filterGame && filterGame[0]) {
                    setGameDraw(filterGame[0])
                }
            })
    }, [lotteryName]);

    useEffect(() => {
        const info = mockGameInfo.filter((item) => item.game === lotteryName);

        if (info && info[0]) {
            setGameInfo(info[0]);
        }

    }, [lotteryName]);

    useEffect(() => {
        if (date) {
            setNextDateGame(formatDateUnix(date, 'dd MMMM yyyy \'года\' в HH:mm:ss'))
        }
    }, [date]);

    if (lotteryName === '5x50') {
        return (
            <Layout>
                <Container>
                    <ContentWrapper>
                        <LogoGameWrapper>
                            <LogoGame src={gameInfo?.srcLogo}/>
                        </LogoGameWrapper>
                        <Content>
                            <InfoLottery lotteryName={lotteryName}>
                                <InfoTitle>Информация о проведении лотерей с использованием электронных лотерейных
                                    билетов</InfoTitle>
                                <InfoContent>
                                    <div dangerouslySetInnerHTML={{__html: gameInfo?.contentInfo?.title}}/>
                                    <div dangerouslySetInnerHTML={{__html: gameInfo?.contentInfo?.rules}}/>
                                </InfoContent>
                            </InfoLottery>
                        </Content>
                    </ContentWrapper>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout>
            <Container>
                <ContentWrapper>
                    <LogoGameWrapper>
                        <LogoGame src={srcLogo} lotteryName={lotteryName}/>
                    </LogoGameWrapper>
                    <Content>
                        <AboutTitle>{contentDraw?.title} <br/> {gameInfo?.title}</AboutTitle>
                        <AboutDescription>
                            {nextDateGame &&
                                <p>{contentDraw?.nextDraw} {gameInfo?.title} № {draw?.number} пройдет {nextDateGame} по&nbsp;московскому
                                    времени.</p>}
                            {contentDraw?.winnings && draw?.betCost &&
                                <p>{contentDraw?.winnings} <b>от {draw?.betCost} рублей.</b></p>}
                            {draw?.superPrize &&
                                <p>{contentDraw?.superPrize} <b>{formatNumber(draw?.superPrize)} рублей.</b></p>}
                            {contentDraw?.maxWin && <p dangerouslySetInnerHTML={{__html: contentDraw?.maxWin}}/>}
                            {contentDraw?.rules && <p dangerouslySetInnerHTML={{__html: contentDraw?.rules}}/>}
                            {draw?.betCost && <p>{contentDraw?.ticket} <b>от {draw?.betCost} рублей.</b></p>}
                        </AboutDescription>
                        <ButtonWrapper>
                            <ButtonLink color={'primary'} href={gameInfo?.buyUrl}>Купить билет</ButtonLink>
                        </ButtonWrapper>
                        <DrawLinksWrapper>
                            <DrawLink>
                                <ModalContainer link={LinkModal}>
                                    <ModalInfo data={futureDraw} lotteryName={gameInfo?.title}/>
                                </ModalContainer>
                            </DrawLink>
                            <DrawLink>
                                <Link href={archiveDrawUrl}>Архив тиражей</Link>
                            </DrawLink>
                        </DrawLinksWrapper>
                    </Content>
                </ContentWrapper>
                <InfoLottery>
                    <InfoTitle>Информация о проведении лотерей с использованием электронных лотерейных
                        билетов</InfoTitle>
                    <InfoContent>
                        <div dangerouslySetInnerHTML={{__html: gameInfo?.contentInfo?.title}}/>
                        {nextDateGame && <p>Дата проведения тиража: {nextDateGame} по московскому времени.</p>}
                        <div dangerouslySetInnerHTML={{__html: gameInfo?.contentInfo?.rules}}/>
                        {draw?.betCost && <p>Цена единичной лотерейной ставки: {draw?.betCost} руб.</p>}
                        {gameInfo?.contentInfo?.eComNaming &&
                            <div dangerouslySetInnerHTML={{__html: gameInfo.contentInfo.eComNaming}}/>
                        }
                    </InfoContent>
                </InfoLottery>
            </Container>
        </Layout>
    )
};

const Layout = styled.div`
	margin: 108px 0 60px;
	
	${media.desktop} {
		margin: 175px 0;
	}
`;
const Container = styled.div`
	max-width: 1022px;
	margin: 0 auto;
	padding: 0 35px;
	display: flex;
	flex-direction: column;
	
	${media.desktop} {
		padding: 0;
	}
`;
const ContentWrapper = styled.div`
	flex-direction: row;
    display: flex;
`;
const Content = styled.article`  
	padding-left: 20px;
	${media.desktop} {
		padding-left: 20px;
	}
`;
const LogoGameWrapper = styled.div`
	max-width: 70px;
    width: 100%;
    text-align: right;
    
    ${media.desktop} {
		max-width: 155px;
	}
`;
const LogoGame = styled.img<{ lotteryName?: string }>`
	margin-top: 8px;
	height: ${({lotteryName}) => lotteryName === 'keno' || '5x2' ? 'auto' : '140px'};
	width: 100%;
`;
const AboutDescription = styled.div`
	p {
		font-size: 12px;
		line-height: 17px;
		
		& + p {
			margin-top: 5px;
		}
		
		${media.desktop} {
			font-size: 16px;
			line-height: 24px;
			
			& + p {
				margin-top: 15px;
			}
		}
	}
`;
const AboutTitle = styled.h1`
	font-size: 25px;
	line-height: 39px;
	letter-spacing: -0.2px;
	text-transform: uppercase;
	color: #EAC141;
	font-size: 22px;
    line-height: 30px;
	margin-bottom: 8px;
	
	${media.desktop} {
		font-size: 32px;
		line-height: 39px;
		margin-bottom: 33px;
	}
`;
const ButtonWrapper = styled.div`
    margin: 32px 0 36px;
    
    ${media.mobile} {
        margin: 28px 0;
    }
    
	a {
		max-width: 254px;
		width: 100%;
		display: inline-block;
		text-align: center;
	}
    
`;

const DrawDateParent = styled.div`
	cursor: pointer;
`;

const DrawDatesList = styled.div<{ show: boolean }>`
	display: ${props => props.show ? 'block' : 'none'};
`;

const DrawLinksWrapper = styled.div`
    margin-bottom: 45px;
    
    ${media.desktop} {
    	margin-bottom: 50px;	
    }
`;
const DrawLink = styled.div`
	font-size: 16px;
	margin-bottom: 21px;
	
	${media.desktop} {
    	margin-bottom: 12px;	
    }
    
	span, a{
		border-bottom: 1px solid #2D3136;
		cursor: pointer;
	}
`;
const InfoLottery = styled.div<{ lotteryName?: string }>`
	color: #747474;
	
	${media.desktop} {	
		${({lotteryName}) => lotteryName !== '5x50' && lotteryName !== 'rapido-ultra' && css`
			padding-left: 175px;
		`};
		
	}
`;
const InfoTitle = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;	
	margin-bottom: 10px;
	
	${media.desktop} {
		margin-bottom: 20px;
	}
`;
const InfoContent = styled.div`
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	
	p {
      margin-top: 6px;
    }
`;
const ModalTitle = styled.div`
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #171A1E;
	margin-bottom: 30px;
`;
const ModalContent = styled.div`
	max-height: 352px;
    overflow: auto;
    line-height: 20px;
    font-size: 12px;
    
    ${media.desktop} {
    	max-height: 464px;
    	font-size: 16px;
    	line-height: 28px;
    }
`;
