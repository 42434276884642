import React from 'react';
import styled from 'styled-components'



export const MobileMenuHeader = styled.div`
  padding-top: 19px;
  margin-bottom: 75px;
`

export const Container =  styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 35px;
`

export const MobileMenuHeaderInner = styled.div`
  display: flex;
  align-items: flex-start;
`