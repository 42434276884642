import React from 'react';
import {useMediaQuery} from "react-responsive";
import {
    PageContainer,
    Content,
    Title,
    Subtitle,
    List,
    ListItem,
    Link,
    PdfBlock,
    SocialWrapper,
} from "../styles";
import { Icon } from "../../../controls/Icon";
import { Social } from "../Social";
import { Header } from "../../Header";
import { WIDTH_BREAKPOINTS } from "../../../common/styles";
import { ROUTES } from "../../../../constants/routes";
import { CONTENT_MAX_WIDTH } from "../styles";

export const RightsPage = () => {

    const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });
    const ICON_WIDTH = isDesktop ? 20 : 42;
    const ICON_HEIGHT = isDesktop ? 26 : 52;

    const clickHandle = function(e: React.MouseEvent) {
        e.preventDefault();
        const el = e.currentTarget;
        const url = el.getAttribute('href');
        window.location.href = (window.origin + url);
    };

    return (
        <PageContainer>
            <Header showLogo menuMaxWidth={CONTENT_MAX_WIDTH} />
            <Content>
                <Title>Правовая информация</Title>
                <Subtitle>
                    для пользователей официального сайта ООО «Спортлото»
                </Subtitle>
                <List>
                    <ListItem bulleted>ООО «Спортлото» является владельцем интернет-сайта sportloto.ru (далее — «Сайт»)</ListItem>
                    <ListItem bulleted>Пользователем Сайта является любое лицо, которое зашло через сеть интернет на Сайт.</ListItem>
                    <ListItem bulleted>Имя и логотип ООО «Спортлото» могут быть использованы исключительно по ограниченной лицензии, предоставляемой ООО «Спортлото» на основании договора.</ListItem>
                    <ListItem bulleted>ООО «Спортлото» не дает гарантии точности, полноты, адекватности воспроизведения третьими лицами любой информации, размещенной на настоящем Сайте, и в безоговорочной форме отказывается от ответственности за ошибки и упущения, совершенные третьими лицами при воспроизведении такой информации.</ListItem>
                    <ListItem bulleted>ООО «Спортлото» не несет ответственности ни за какие убытки, включая реальный ущерб и упущенную выгоду, возникшие в связи с настоящим Сайтом, его использованием, невозможностью использования, помехами, дефектами, вирусами или сбоем в работе системного оборудования или программного обеспечения Сайта.</ListItem>
                    <ListItem bulleted>Вводя какие-либо данные на настоящем Сайте, пользователь подтверждает свое безусловное согласие на обработку, передачу и использование ООО «Спортлото» любых данных пользователя, включая его персональные данные, введенные при регистрации на Сайте или переданные при обращении в ООО «Спортлото», осознавая и соглашаясь, что обработка данных осуществляется по усмотрению ООО «Спортлото» любыми способами, допустимыми действующим законодательством Российской Федерации, в том числе с применением средств шифрования.</ListItem>
                    <ListItem bulleted>Для получения лотерейного выигрыша от 15 000 рублей участнику необходимо пройти процедуру идентификации, согласно Федеральным законам от 11.11.2003 &#8470; 138-ФЗ &laquo;О лотереях&raquo; и от 07.08.2001 &#8470; 115-ФЗ &laquo;О противодействии легализации (отмыванию) доходов, полученных преступным путем, и&nbsp;финансированию терроризма&raquo;.&nbsp;
                        <Link href={ROUTES.IDENTIFICATION}>Узнать подробнее</Link>
                    </ListItem>
                    <ListItem bulleted><Link href="/Politika_v_otnoshenii_obrabotki_personalnih_dannih_2023.pdf" onClick={clickHandle}>Политика в отношении обработки персональных данных ООО «Спортлото»</Link></ListItem>
                    <ListItem bulleted><Link href="/soglashenie_sportloto.pdf" onClick={clickHandle}>Соглашение относительно порядка использования официального сайта ООО «Спортлото»</Link></ListItem>
                    </List>
                <div>
                    <PdfBlock>
                        <Icon name="pdf" width={ICON_WIDTH} height={ICON_HEIGHT} />
                        <Link href="/usloviya_minfina_prikaz_ot_07.12.20.pdf" onClick={clickHandle}>
                            Условия проведения лотерей
                        </Link>
                    </PdfBlock>
                </div>
                { isDesktop && <SocialWrapper><Social /></SocialWrapper>}
            </Content>
        </PageContainer>
    );
};
