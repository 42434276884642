import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../common/styles';

import { ButtonLink } from '../../controls/Button';
import { Header } from '../Header';

import beInGameBackground from './img/be-in-game-bg.jpg';
import sportloto_logo from './img/sportloto-logo.svg';
import {Social} from "../InfoPages/Social";

export const BeInGame = () => {

  return (
    <Layout id='be-in-game'>
      <Header white />
      <LogoWrapper>
        <img src={sportloto_logo} alt="Спортлото" />
      </LogoWrapper>
      <TitleWrapper>
        <Title>
          <TitleTop>
            «спортлото»
          </TitleTop>
          <TitleBottom>
            50 лет
           <span>50 лет</span>
          </TitleBottom>
        </Title>
      </TitleWrapper>
      <ButtonWrapperMobile>
        <ButtonLink href='https://stoloto.ru'>Участвовать</ButtonLink>
      </ButtonWrapperMobile>
      <ButtonWrapperDesktop>
        <ButtonLink href='https://stoloto.ru'>Участвовать</ButtonLink>
      </ButtonWrapperDesktop>
      <Social />
    </Layout>
  )
};

const Layout = styled.div`
  background: url(${beInGameBackground}) center center #171A1E no-repeat;
  background-size: cover;
  min-height: 640px;
  padding: 85px 0 45px;
  overflow: hidden;
  position: relative;
  .socials-menu {
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translate(0, -50%);
    ${media.mobile} {
      display: none;
    }
  }
  ${media.desktop} {
    background: url(${beInGameBackground}) center #171A1E no-repeat;
    background-size: cover;
    min-height: 900px;
    padding: 190px 0 175px;
    font-size: 144px;
    line-height: 149px;
  }
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 125px;
    ${media.desktop} {
      margin-bottom: 48px;
    }
    img {
      width: 240px;
      ${media.desktop} {
        width: auto;
      }
    }
`;

const TitleWrapper = styled.div`
  margin-bottom: calc((150px * 1.15) + 5px);
  padding: 0 35px;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 375px) {
    margin-bottom: 150px;
  }
  ${media.desktop} {
    margin-bottom: 80px;
  }
`;

const titleFont = css`
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  font-size: calc(43px * 0.85);
  line-height: calc(70px * 0.85);
  @media screen and (min-width: 375px) {
    font-size: 43px;
    line-height: 70px;
  }
  ${media.desktop} {
    font-size: calc(130px * 0.85);
    line-height: calc(135px * 0.85);
  }
  @media screen and (min-width: 1440px) {
    font-size: 130px;
    line-height: 135px;  
  }
`;

export const Title = styled.h1``;

export const TitleTop = styled.span`
  ${titleFont};
  color: #fff;
  display: block;
  animation: 1s bounceInLeft;
  @keyframes bounceInLeft {
    0%, 60%, 75%, 90%, to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(-3000px,0,0) scaleX(3)
    }

    60% {
        opacity: 1;
        transform: translate3d(25px,0,0) scaleX(1)
    }

    75% {
        transform: translate3d(-10px,0,0) scaleX(.98)
    }

    90% {
        transform: translate3d(5px,0,0) scaleX(.995)
    }

    to {
        transform: translateZ(0)
    }
}
`;

const TitleBottom = styled.span`
  ${titleFont};
  color: #EAC141;
  position: relative;
  display: block;
  white-space: nowrap;
  animation: 1s bounceInRight;
  margin: -20px 0 0 calc(80px * 0.85);
  @media screen and (min-width: 375px) {
    margin: -20px 0 0 80px;
  }
  ${media.desktop} {
    margin: -15px 0 0 calc(360px * 0.85);
  }
  @media screen and (min-width: 1440px) {
    margin: -15px 0 0 360px;  
  }
  span {
    position: absolute;
    color: transparent;
    -webkit-text-stroke: 1px #EAC141;
    top: 7px;
    left: 5px;
    ${media.desktop} {
      top: 11px;
      left: 5px;
    }
  }
  
  @keyframes bounceInRight {
    0%,60%,75%,90%,to {
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
    }

    0% {
        opacity: 0;
        transform: translate3d(3000px,0,0) scaleX(3)
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px,0,0) scaleX(1)
    }

    75% {
        transform: translate3d(10px,0,0) scaleX(.98)
    }

    90% {
        transform: translate3d(-5px,0,0) scaleX(.995)
    }

    to {
        transform: translateZ(0)
    }
  }
`;

const ButtonWrapperStyles = css`
    display: flex;
    justify-content: center;
`;

const ButtonWrapperMobile = styled.div`
  ${ButtonWrapperStyles};
  .button {
    width: 250px;
  }
  ${media.desktop} {
    display: none;
  }
`;

const ButtonWrapperDesktop = styled.div`
  ${ButtonWrapperStyles};
  .button {
    width: 250px;
  }
  ${media.mobile} {
    display: none;
  }
`;