import React from 'react';
import {
    SectionContainer,
    Triangles,
} from "./styles";
import { TitleBlock } from "./TitleBlock";
import { Testimonies } from "./Testimonies";


export const HowItWas = () => (
    <SectionContainer id='how-it-was'>
        <TitleBlock>
            Как <br/>
            {"\u00A0".repeat(5)}
            это<br />
            было
        </TitleBlock>
        <Testimonies />
        <Triangles />
    </SectionContainer>
);