import React from "react";
import styled from 'styled-components'
import { Button } from "../controls/Button";
import { media } from "../common/styles";
import { USER_IDENTIFICATION_STATUSES } from "../../constants";

interface IdentificationProps {
    identificationStatus: string,
    buttonClickHandler: () => void,
}

export const Identification = ({ identificationStatus, buttonClickHandler }: IdentificationProps) => (
    <Wrapper>
        <Text>
            Статус идентификации: <Status>{USER_IDENTIFICATION_STATUSES[identificationStatus as keyof typeof USER_IDENTIFICATION_STATUSES] || 'не определено'}</Status>
        </Text>
        <ButtonWrapper>
            <Button color="primary" onClick={buttonClickHandler}>На главную</Button>
        </ButtonWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 30px;
    
    ${media.desktop} {
        margin-bottom: 65px;
    }
`;
const Text = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    
    ${media.mobile} {
        display: flex;
        flex-direction: column;
        margin-bottom: 27px;
        line-height: 3;
    }
    
    ${media.desktop} {
        margin: 65px 94px 49px;
    }
`;
const Status = styled.span`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;    
    color: #EAC141;
`;
const ButtonWrapper = styled.div`
    a {
        padding: 15px 75px;
        display: inline-flex;
    }
`;