import React from 'react';
import {
    Title,
    TestimonyContainer,
    TestimonyWrapper,
    Text
} from "./styles";

export type TUnderlayType = 'left' | 'right';

interface ITestimonyProps {
    data: {
        name: string;
        text: string;
    };
    underlay: TUnderlayType,
    isMobile?: boolean,
}

export const Testimony = ({ data: {name, text }, underlay, isMobile}:ITestimonyProps,) => (
    <TestimonyWrapper underlayDirection={underlay} isMobile={isMobile} >
        <TestimonyContainer>
            <Title>
                {name}
            </Title>
            <Text>
                {text}
            </Text>
        </TestimonyContainer>
    </TestimonyWrapper>

)