import styled from 'styled-components';
import appleSvg from '../../controls/Icon/icons/apple.svg';
import androidSvg from '../../controls/Icon/icons/android.svg';
import { media } from "../../common/styles";

const APP_ICONS:{[key: string]: string} = {
    apple: appleSvg,
    android: androidSvg,
};

const BORDER_COLOR = 'rgba(172, 172, 172, 0.2)';

export const FooterContainer = styled.footer`
    width: 100%;
    height: 270px;
    background: #2D3136;
    padding-top: 44px;
    
   ${media.mobile} {
      height: auto;
      padding: 0;
    }
`;

export const FooterBlock = styled.div`
    height: 185px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: fit-content;
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 209px; 
    
    ${FooterBlock}:first-child {
      width: auto;
    }
    
    ${media.mobile} {
      width: 100%;
      padding: 27px 35px 57px;
      flex-direction: column;
    }
`;

export const PhoneBlock = styled(FooterBlock)`

    ${media.desktop} {
      padding-left: 71px;
      border-left: 1px solid ${BORDER_COLOR};
      margin-left: 40px;
    }  
    
    ${media.mobile} {
      padding: 25px 0;
      height: auto;
      border-width: 1px 0;
      border-color: ${BORDER_COLOR};
      border-style: solid;
    }    
`;

export const Text = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: white;
`;

export const Phone = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  
  ${media.small} {
    font-size: 28px;
  }  
`;

interface TAppIconProps {
    icon: string;
}

export const AppIcon = styled.div<TAppIconProps>`
    width: 13px;
    height: 16px;
    background: url(${props => APP_ICONS[props.icon]}) no-repeat;
    background-size: contain;
    &:not(:last-child) {
       margin-right: 7px;
       
       ${media.mobile} {
          margin-right: 6px;
        }   
    }
    
    ${media.mobile} {
      width: 12px;
      height: 14px;
    }    
`;

export const AppButton = styled.a`
    display: flex;
    align-items: center;
    padding: 10px 18px;
    left: 539px;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    border: 2px solid rgba(255,255,255, 0.2);
    color: white;
    text-decoration: none;
    
    ${AppIcon}:last-child {
      margin-right: 11px;
    }
    
    ${media.mobile} {
      font-size: 15px;
      padding: 10px 14px;
    }    
`;

export const TopMobileSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    margin-bottom: 48px;
    
    ${FooterBlock} {
      height: auto;
      justify-content: flex-start;
      &:first-child {
        width: auto;
      }
    }
    
    ${AppButton} {
      margin-bottom: 30px;
    }
`;
