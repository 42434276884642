import styled, { css } from 'styled-components'
import { IArrowProps } from "./index";
import { media } from "../../../common/styles";

const SLIDER_WIDTH_MOBILE = 350;
const ARROW_WIDTH = 52;
const SPACE_BEETWEEN_ARROWS = 16;
const ARROW_INDENT_MOBILE = (SLIDER_WIDTH_MOBILE - ARROW_WIDTH*2 - SPACE_BEETWEEN_ARROWS)/2;

export const Container = styled.div`
  position: relative;
  width: 601px;  
  top: 155px;
  z-index: 20;
  
  .slick-list {
    z-index: 1;
  }
  
  ${media.mobile} {
    position: relative;
    top: auto;
    right: auto;
    width: ${SLIDER_WIDTH_MOBILE}px;
    margin: 0 auto 108px;
  }  
  
  ${media.small} {
    height: auto;
    width: 100%;
    padding: 0 10px;    
  }  
`;

type TArrowType = 'prev' | 'next';

interface IDirectionTypes {
    indent: 'left' | 'right';
}

export const Slide = styled.div<IDirectionTypes>`
  padding-${props => props.indent}: 78px;
  width: 100%;
  padding-top: 16px;
  
  &:first-child {
    ${media.desktop} {
      margin-bottom: 30px;
    }
  }
  
  ${media.mobile} {
    padding: 22px 0 0 23px;
    right: auto;
  }
`;

const getArrowCss = (type: TArrowType) => {
    switch(type) {
        case 'prev':
            return css`
              top: 400px;
              left: 0;              
              &:after {
                left: 0 !important;
                border-width: 0 0 1px 1px;
              }   
              ${media.mobile} {
                top: auto;
                bottom: -60px;
                left: ${ARROW_INDENT_MOBILE}px;
              }        
              ${media.small} {
                top: auto;
                bottom: -60px;
                left: 30%;
              }      
          `;
        case 'next':
            return css`
              top: 125px;
              right: 0;              
              &:after {
                right: 1px;
                border-width: 1px 1px 0 0;
              }            
              ${media.mobile} {
                top: auto;
                bottom: -60px;
                right: ${ARROW_INDENT_MOBILE}px;             
              }
              ${media.small} {
                top: auto;
                bottom: -60px;
                right: 30%;
              }          
          `
    }
};

export const Arrow = styled.div<IArrowProps>`
  display: block;
  position: absolute;
  width: ${ARROW_WIDTH}px;
  height: 17px;
  z-index: 50;
  cursor: pointer;
  
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid black;
    top: 7px;
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    width: 11px;
    height: 11px;
    border-style: solid;
    border-color: black;
    transform: rotate(45deg);
  }
  
  ${props => getArrowCss(props.type)};
`;