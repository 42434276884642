import React from "react";
import styled from "styled-components";
import { ReactComponent as SportlotoLogoSvg } from './sportloto-logo.svg'

interface ISportlotoLogoProps {
  className?: string;
}

const SportlotoLogoComponent = ({className}:ISportlotoLogoProps) =>
    <SportlotoLogoSvg className={className} />;

export const SportlotoLogo = styled(SportlotoLogoComponent)`
  width: 155px;
  height: 26px;
  color: inherit;
`;