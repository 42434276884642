import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from "react-router-dom";

import { media } from '../../common/styles';

import { MobileMenuHeader, Container, MobileMenuHeaderInner } from './MobileMenuHeader'

import burger from './img/burger.svg'
import cross from './img/cross.svg'
import Sportloto from './img/Sportloto.svg'


interface IProps {
  open: boolean,
  children: React.ReactNode,
  handleToogleOpen: () => void
}

export const MobileMenu = ({ open, children, handleToogleOpen }: IProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Layout>
      <MenuContainer>
        <MenuButton onClick={handleToogleOpen}><img src={burger} alt='Меню' /></MenuButton>
        <MobileMenuInner ref={ref} open={open}>
          <MobileMenuHeader>
            <Container>
              <MobileMenuHeaderInner>
                <MenuButton onClick={handleToogleOpen}><img src={cross} alt='Меню' /></MenuButton>
                <MenuLogo to='/' onClick={handleToogleOpen}>
                  <img src={Sportloto} alt="Спортлото" />
                </MenuLogo>
              </MobileMenuHeaderInner>
            </Container>
          </MobileMenuHeader>
          {children}
        </MobileMenuInner>
      </MenuContainer>
    </Layout>
  )
};

MobileMenu.defaultProps = {
  open: false
};


const Layout = styled.div`
  ${media.desktop} {
    display: none;
  }
`;

const MenuContainer = styled.div`
  position: relative;
`;

const MenuButton = styled.button<{onClick: () => void}>`
  border: none;
  background: none;
  outline: none;
`;

const MobileMenuInner = styled.div<{ open?: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background: #000;
    color: white;
    transition: 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    z-index: 10;
    ${props => props.open && css`
      opacity: 1;
      visibility: visible;
    `}
    .menu-link + .menu-link {
      margin-top: 50px;
    }
    & > .menu-link {
      text-align: center;
    }
`;

const MenuLogo = styled(Link)`
  display: inline-flex;
  margin: auto;
`;

MobileMenuInner.defaultProps = {
  open: false
};