const oauthHost = (window as any).stolotoOauth;
export const redirectHost = `${window.location.protocol}//${window.location.host}`;

export const GRANT_TYPE = 'authorization_code';
export const URL_OAUTH_STOLOTO = `${oauthHost}/oauth/authorize?response_type=code&redirect_uri=${redirectHost}&client_id=Sportloto&scope=read%20write&state=ffef9f3c-bb5c-4efd-8b04-a8ac72e68959`;

export const USER_IDENTIFICATION_STATUSES = {
    PASSED_FULL: "идентифицирован",
    PASSED_SIMPLE: "упрощенно идентифицирован",
    NOT_PASSED: "не идентифицирован",
    IN_PROGRESS: "данные на проверке",
    EXPIRED: " срок действия идентификации истек ",
    DOCUMENT_INVALID: "документы некорректны",
    UNKNOWN: "не определено"
};
