import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { RightsPage } from "../components/widgets/InfoPages/RightsPage";

const Rights = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
    }, []);

    return (
        <main>
            <RightsPage />
        </main>
    );
};

export default Rights;
