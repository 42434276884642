import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../Link';
import { media, WIDTH_BREAKPOINTS } from '../../common/styles';

interface IProps {
  href: string,
  children: React.ReactNode,
  onClick?: (e: React.MouseEvent) => void
}

export const MenuLink = ({ href, children, onClick }: IProps) => {
    return <StyledLink className='menu-link' onClick={onClick} href={href}>{children}</StyledLink>
};

const linkStyles = css`
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
  //white-space: nowrap;
  /*${media.big} {
    font-size: 10px;
  }*/
`;

const StyledLink = styled(Link)`${linkStyles}`;