import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { DisclosurePage } from "../components/widgets/InfoPages/DisclosurePage";

const Disclosure = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
  }, []);

  return (
    <main>
      <DisclosurePage />
    </main>
  );
};

export default Disclosure;
