import React, { FC } from 'react';
import {
    MainTitleContainer,
    OutlineText,
    SubTitle,
    Title,
    BgTriangle,
    TitleContainer
} from "./styles";

export const TitleBlock:FC = ({ children }) => (
    <TitleContainer>
        <BgTriangle />
        <MainTitleContainer>
            <Title>
                {children}
            </Title>
            <OutlineText>
                {children}
            </OutlineText>
        </MainTitleContainer>
        <SubTitle>Истории от наших участников в <b>соцсетях</b></SubTitle>
    </TitleContainer>
);