import styled, { css } from 'styled-components';

interface IProps {
  fontSize?: number | string
  color?: 'primary' | 'secondary'
  disabled?: boolean,
  href?: string
}

const ButtonStyles = (props: IProps) => {
  const { fontSize, color, disabled } = props;
  return css`
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  border-radius: 11px;
  padding: 16px 12px;
  outline: none;
  cursor: pointer;
  color: currentColor;
  transition: 0.3s all;
  text-align: center;
  ${fontSize === 'adaptive' && `
      @media screen and (width: 414px){
          font-size: 15px;
      }
  `}
  ${color === 'primary' && !disabled && `
      background: #EAC141;
      border: 1px solid transparent;
      &:active {
        background: #AE9347;
      }
  `}
  ${disabled && color === 'primary' && `
    opacity: 0.48;
    cursor: not-allowed;
  `}
  ${color === 'secondary' && !disabled && `
    background: none;
    border: 1px solid #000;
    color: #000;
    &:hover {
      border: 1px solid #EAC141;
      color: #EAC141;
    }
    &:active {
      border: 1px solid #AE9347;
      color: #AE9347;
    }
  `}
  ${disabled && color === 'secondary' && `
    background: none;
    border: 1px solid #000;
    opacity: 0.48;
    cursor: not-allowed;
  `}`;
};

export const Button = styled.button.attrs(() => ({
  className: 'button'
}))`
    ${ButtonStyles}
`;

export const ButtonLink = styled.a.attrs(() => ({
  className: 'button'
}))`
  ${ButtonStyles};
  text-decoration: none;
`;



Button.defaultProps = {
  color: 'primary'
};

ButtonLink.defaultProps = {
  color: 'primary'
};