import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from "react-router";
import { media, WIDTH_BREAKPOINTS } from '../../common/styles';
import { MenuLink } from '../../controls/MenuLink'
import { MobileMenu } from '../../controls/MobileMenu'
import { ProfileButton } from '../../controls/ProfileButton'
import { ModalContainer } from "../../modalContainer";
import {GRANT_TYPE, redirectHost, URL_OAUTH_STOLOTO} from "../../../constants";
import { Identification } from "../../modalContainer/Identification";
import { SportlotoLogo } from "../../controls/SportlotoLogo";
import { Link } from "../../controls/Link";
import {api, apiOauth} from "../../../api";
import { ROUTES } from '../../../constants/routes';

interface IHeaderProps {
  className?: string;
  showLogo?: boolean;
  white?: boolean;
  menuMaxWidth?: number,
}

export const Header = ({className, showLogo, white, menuMaxWidth}: IHeaderProps) => {

  const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('code');
  const [showModal, setShowModal] = useState(false);
  const [accessToken, setAccessToken] = useState<string>();
  const [isAuth, setIsAuth] = useState(window.localStorage.getItem('isLoggedIn') === 'true');
  const [stolotoIdentification, setStolotoIdentification] = useState('UNKNOWN');

  const handleClickAuth = () => {
    window.location.href = URL_OAUTH_STOLOTO;
  };
  const handleClickNotAuth = () => {
    window.localStorage.removeItem('isLoggedIn');
    setIsAuth(false);
  };
  const history = useHistory();
  const handleClickMenu = (url: string) => {
    history.push(url);
    changeMenuOpen(false)
  };
  const [menuOpen, changeMenuOpen] = useState(false);
  const handleModalButtonClick = () => {
    setShowModal(false);
    history.push(ROUTES.MAIN);
  };

  useEffect(() => {
    token && apiOauth.fetchOauthToken(GRANT_TYPE, redirectHost, token)
        .then(accessTokenDetails => setAccessToken(accessTokenDetails));
  }, [token]);

  useEffect(() => {
    accessToken && api.fetchUserStatusDetails(accessToken)
        .then(ownerInfoDetails => {
          if (ownerInfoDetails.hasOwnProperty('sportloto')) {
            window.localStorage.setItem('isLoggedIn', 'true');
            setIsAuth(true);
            setStolotoIdentification(ownerInfoDetails.sportloto);
            setShowModal(true);
          }
        });
  }, [accessToken]);

  const authButton =
      isAuth ?
        <ProfileButton onClick={handleClickNotAuth}>Выйти</ProfileButton> :
        <ProfileButton onClick={handleClickAuth}>Войти</ProfileButton>;

  return (
      <Layout className={className} white={white}>
        {
          isDesktop ?
              <Container className="menu">
                {
                  showLogo &&
                  <LogoLink href="/" white={white}>
                    <SportlotoLogo />
                  </LogoLink>
                }
                <Menu maxWidth={menuMaxWidth}>
                  <MenuLink href='/about'>О компании</MenuLink>&#8195;
                  <MenuLink href='https://museum.stoloto.ru/home'>История</MenuLink>&#8195;
                  <MenuLink href='https://www.stoloto.ru/map/?int=sitemap'>Где купить</MenuLink>&#8195;
                  <MenuLink href='https://www.stoloto.ru/winners'>Победители</MenuLink>&#8195;
                  <MenuLink href='/news'>Новости</MenuLink>&#8195;
                  <MenuLink href='/right'>Правовая&nbsp;информация</MenuLink>&#8195;
                  <MenuLink href='/contacts'>Контакты</MenuLink>
                </Menu>
                { authButton }
              </Container> :
              <Container>
                <MobileMenuBlock className="mobile-menu-block">
                  <MobileMenu open={menuOpen} handleToogleOpen={() => changeMenuOpen(!menuOpen)}>
                    <MenuLink href='/about' onClick={() => handleClickMenu('/about')}>О компании</MenuLink>
                    <MenuLink href='https://museum.stoloto.ru/home'>История</MenuLink>
                    <MenuLink href='https://www.stoloto.ru/map/?int=sitemap'>Где купить</MenuLink>
                    <MenuLink href='/news' onClick={() => handleClickMenu('/news')}>Новости</MenuLink>
                    <MenuLink href='/right'>Правовая информация</MenuLink>
                    <MenuLink href='/contacts' onClick={() => handleClickMenu('/contacts')}>Контакты</MenuLink>
                  </MobileMenu>
                  {
                    showLogo &&
                    <LogoLink href="/" white={white}>
                      <SportlotoLogo />
                    </LogoLink>
                  }
                  { authButton }
                </MobileMenuBlock>
              </Container>
        }
        {showModal &&
          <ModalContainer isOpen={showModal}>
            <Identification identificationStatus={stolotoIdentification} buttonClickHandler={handleModalButtonClick} />
          </ModalContainer>
        }
      </Layout>
  )
};

interface ILayoutProps {
  className?: string,
  white?: boolean,
}

const Layout = styled.header<ILayoutProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 20px;
  color: ${props => props.white ? 'white' : 'black'};
  
  ${media.desktop} {
    padding-top: 53px;    
  }
  
  &.header-about {
    .menu-main {
      .menu-link {
        color: #000;
      }
    }
    .menu, .mobile-menu-block {
      .profile-button {
        color: #000;
        &__user {
          fill: #000;
        }
      }
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin: auto;
  padding: 0 35px;
  //justify-content: left;
  
  ${media.desktop} {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    display: table;
    width: 100%;
    font-size: 12px;
    line-height: 19px;
    
    .profile-button {
      //position: absolute;
      //right: 35px;
      display: table-cell;
      vertical-align: top;
      width: 100px;
      text-align: left;
    }
  }
`;

interface IMenuProps {
  maxWidth?: number
}

export const Menu = styled.nav<IMenuProps>`
  display: none;
  //justify-content: center;
  //align-items: center;
  position: relative;  
  color: inherit;
  
  //flex-wrap: wrap; 
  //justify-content: left;
  
  ${props => props.maxWidth && css`
    justify-content: flex-start;
    max-width: ${props.maxWidth}px;
    flex: 0 1 auto;
  `};
    
  .menu-link/*:not(:first-child)*/ {
    //margin-left: 65px;
    margin-left: 4%;      
  } 
  
  ${media.desktop} {
    //display: flex;
    display: table-cell;
    vertical-align: top;
    text-align: center;
  }
  
  ${media.big} {
    justify-content: center;
     .menu-link/*:not(:first-child)*/ {
        //margin-left: 25px;
        margin-left: 1%;
     }
  }  
`;

const MobileMenuBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  color: inherit;
`;

interface ILogoLinkProps {
  white?: boolean;
}

const LogoLink = styled(Link)<ILogoLinkProps>`
  //position: absolute;
  //left: 35px;
  color: ${props => props.white ? 'white' : 'inherit'};
  display: table-cell;
  vertical-align: top;
  
  ${media.mobile} {
    left: auto;
    position: relative;
  }  
`;
