import React from 'react';
import { GameInfo } from '../components/widgets/GameInfo';
import { Header } from '../components/widgets/Header';
import { useMediaQuery } from 'react-responsive';
import { WIDTH_BREAKPOINTS } from '../components/common/styles';
import { Social } from '../components/widgets/InfoPages/Social';
import { SocialWrapper } from '../components/widgets/InfoPages/styles';

export const RapidoUltra = () => {
    const isDesktop = useMediaQuery({ minWidth: WIDTH_BREAKPOINTS.DESKTOP });

    return (
        <main>
            <Header showLogo className='header-about'/>
            <GameInfo lotteryName='rapido-ultra' isDesktop={isDesktop}/>
            { isDesktop && <SocialWrapper><Social /></SocialWrapper>}
        </main>
    );
};
