import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photosBackground from './img/photos.jpg'
import arrowSVG from './img/icon-link-white.svg'
import {NEWS as news} from './mock';
import {media, WIDTH_BREAKPOINTS} from "../../common/styles";
import {useMediaQuery} from "react-responsive";

const Arrows = ({firstSlider, lastSlider, gotoPrev, gotoNext}:any) => (
    <ArrowWrapper>
        <Button firstSlider={firstSlider} variables={'prev'} onClick={()=>gotoPrev()}></Button>
        <Button lastSlider={lastSlider} onClick={()=>gotoNext()}></Button>
    </ArrowWrapper>
);

export const PhotoHall = () => {

    const slider = useRef<any>();
    const textSlider = useRef<any>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const isMobile = useMediaQuery({ maxWidth: WIDTH_BREAKPOINTS.MOBILE });

    const [sliderTextRef, setSliderTextRef] = useState();
    const [mainSliderRef, setMainSliderRef] = useState();

    useEffect(() => {
        setSliderTextRef(textSlider.current);
    }, [textSlider.current]);

    useEffect(() => {
        setMainSliderRef(slider.current);
    }, [slider.current]);

    const sliderSettings = {
        dots: false,
        infinite: false,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const mainSliderSettings = {
        beforeChange: (oldIndex: number, newIndex: number) => {
            setCurrentIndex(newIndex);
        },
        responsive: [
            {
                breakpoint: WIDTH_BREAKPOINTS.MOBILE,
                settings: {
                    asNavFor: sliderTextRef,
                },
            },
        ],
    };


    const gotoNext = () => {
        slider.current.slickNext()
    };
    const gotoPrev = () => {
        slider.current.slickPrev()
    };
    return (
        <Layout id='photo-hall'>
            <Content>
                <SliderContainer>
                    <Slider {...sliderSettings} {...mainSliderSettings} ref={slider}>
                        {
                            news.map((item: any, index) => (
                                <div key={index}>
                                    <SliderItem>
                                        <ImageWrapper>
                                            {item.watermark && <Watermark>{item.waterMark}</Watermark>}
                                            <Image src={item.photo} alt=""/>
                                            <Label>{item.label}</Label>
                                        </ImageWrapper>
                                        {
                                            !isMobile &&
                                            <ContentWrapper>
                                                <Title>{item.title}</Title>
                                                <SubTitle>{item.subTitle}</SubTitle>
                                                <Description dangerouslySetInnerHTML={{"__html": item.description}}></Description>
                                            </ContentWrapper>
                                        }
                                    </SliderItem>
                                </div>
                            ))
                        }
                    </Slider>
                </SliderContainer>
                {
                    news.length > 1 &&
                    <Arrows
                        firstSlider={currentIndex === 0}
                        lastSlider={currentIndex === news.length - 1}
                        gotoNext={gotoNext}
                        gotoPrev={gotoPrev}
                    />
                }

                {isMobile &&
                    <SliderContainer>
                        <Slider {...sliderSettings} ref={textSlider} asNavFor={mainSliderRef} >
                            {
                                news.map((item: any, index) => (
                                    <div key={index}>
                                        <SliderItem>
                                            <ContentWrapper>
                                                <Title>{item.title}</Title>
                                                <SubTitle>{item.subTitle}</SubTitle>
                                                <Description dangerouslySetInnerHTML={{"__html": item.description}}></Description>
                                            </ContentWrapper>
                                        </SliderItem>
                                    </div>
                                ))
                            }
                        </Slider>
                    </SliderContainer>
                }
            </Content>
        </Layout>
    )
};


export const Layout = styled.div`
  background: #171A1E;
  position: relative;
  overflow: hidden;
  padding: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  ${media.desktop} {
      padding: 0;
      height: 100vh;
  }
    
  &:after {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    background: url(${photosBackground}) 0 0 #171A1E repeat;
    transform: rotate(-30deg);
    ${media.mobile} {
        background-size: 25%;
    }
  }
  
  .slick-slider{
    z-index: 1;
  }
  
  .slick-next,
  .slick-prev {
        &:before {
            display: none!important;
      }
  }
  
  .slick-slide div {
    outline: none;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const SliderContainer = styled.div`
  width: 100%;
  
    ${media.mobile} {
        &:first-child {
          margin-bottom: 10px;
        }
    }  
`;

export const SliderItem = styled.div`
    width: 100%;
    display: flex;
    z-index: 1;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    ${media.desktop} {
        flex-direction: row;
    }
`;
export const ImageWrapper = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    align-items: center;
    display: flex;
    
    ${media.desktop} {
        width: 564px;
        height: 443px;
        margin-left: 90px;
    }

`;
export const Image = styled.img`
    width: 100%;
    height: auto;
    padding-left: 16px;
    
    ${media.desktop} {
        padding-left: 0;
    }
`;
export const ContentWrapper = styled.div`
    width: 100%;
    position: relative;
    
    ${media.desktop} {
        width: 520px;
        margin-right: 190px;
        padding-left: 80px;
    }
`;
export const Label = styled.h1`
    font-family: Montserrat;
    font-weight: 900;
    font-size: 40px;
    text-transform: uppercase;
    color: #EAC141;
    transform: rotate(180deg);
    position: absolute;
    white-space: nowrap;
    writing-mode: vertical-lr;
    top: 0;
    bottom: 0;
    text-align: end;
    letter-spacing: 0.9px;
    
    ${media.desktop} {
        right: -13px;
        font-size: 90px;
        letter-spacing: 2px;
        text-align: center;
    }
    
    ${media.mobile} {
        margin-top: 12px;
        left: 8px;
    }
    
`;
export const Title = styled.h3`
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 15px 0;
    padding-top: 0;
    font-size: 16px;
    line-height: 20px;
    
    ${media.desktop} {
        margin: 0 0 24px;
        font-size: 20px;
        line-height: 24px;
    }
`;
export const SubTitle = styled.h4`
    font-family: Montserrat;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #EAC141;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 2px;
    
    ${media.desktop} {
        margin: 0 0 18px;
        line-height: 20px;
    }
`;
export const Description = styled.div`
    font-family: Montserrat;
    font-size: 12px;
    line-height: 17px;  
    color: #FFFFFF;
    padding-bottom: 30px;
    
    ${media.desktop} {
        font-size: 16px;
        line-height: 24px;  
        padding-bottom: 50px;
    }
`;
export const ArrowWrapper = styled.div`
    
    position: absolute;
    display: flex;
    justify-content: center;
    padding-left: 220px;
    z-index: 10;
    width: 100%;
    bottom: 15px;
    
    ${media.mobile} {
        position: relative;
        padding: 0;
        justify-content: flex-start;
        margin-top: 35px;
    }
`;
export const Button = styled.button<any>`
    background: url(${arrowSVG}) center center no-repeat;
    border: none;
    color: transparent;
    width: 55px;
    height: 22px;
    cursor: pointer;
    padding: 0;
    position: relative;
    transform: ${props => props.variables === 'prev' ? 'rotate(180deg)' : 'none'};
    opacity: ${props => props.firstSlider || props.lastSlider ? '.5' : '1'};
    
    &:first-child{
        margin-right: 10px;
        top: -1px;
    }
    
    &:focus{
        outline: none;
    }
`;
const Watermark = styled.div`
    position: absolute;
    left: 33px;
    bottom: 15px;
    font-weight: 500;
    font-size: 8.83951px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5893px;
    text-transform: uppercase;
    color: #171A1E;
    
    ${media.desktop} {
        left: 12px;
    }
`;